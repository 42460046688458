const links = {
  defaultTitle: 'New Way - Transforme seu atendimento digital com o Mktzap',
  defaultDescription:
    'A New Way é pioneira em soluções digitais para atendimento humanizado. Conheça o Mktzap, plataforma com chatbot que integra diversos canais de comunicação.',
  defaultThumbnail:
    'https://newway109680549.wpcomstaging.com/wp-content/uploads/2021/03/thumb-metatag.png',
  home: {
    root: '/',
    title: 'New Way - Transforme seu atendimento digital com o Mktzap',
    sitemap: true,
  },
  homeV1: {
    root: '/home-v1',
    title: 'New Way - Transforme seu atendimento digital com o Mktzap',
  },
  contracts: {
    root: '/contratos',
    title: 'New Way - Contratos',
  },
  plans: {
    root: '/planos',
    title: 'Planos e Preços - Mktzap',
    description:
      'Conheça os planos e preços do Mktzap e entenda qual melhor atende às necessidades do seu negócio, seja ele de pequeno, médio ou grande porte. Confira!',
    sitemap: true,
  },
  segments: {
    root: '/segmentos',
    title: 'Principais segmentos - Mktzap',
    description:
      'Saiba como o Mktzap, plataforma de atendimento e chatbot, pode melhorar a comunicação digital de empresas dos mais diversos segmentos. Clique aqui e confira!',
    sitemap: true,
    ecommerce: {
      root: '/segmentos/ecommerce',
      title: 'Mktzap para o segmento de E-commerce - New Way',
      description:
        'Possui uma empresa no segmento de E-commerce? Saiba como o Mktzap, plataforma de atendimento e chatbot, pode melhorar a sua comunicação digital.',
      sitemap: true,
    },
    financial: {
      root: '/segmentos/financeiro',
      title: 'Mktzap para o segmento de Serviços Financeiros - New Way',
      description:
        'Possui uma empresa no segmento de Serviços Financeiros? Saiba como o Mktzap, plataforma de atendimento e chatbot, pode melhorar a sua comunicação digital.',
      sitemap: true,
    },
    healthcare: {
      root: '/segmentos/saude',
      title: 'Mktzap para o segmento de Saúde - New Way',
      description:
        'Possui uma empresa no segmento de Saúde? Saiba como o Mktzap, plataforma de atendimento e chatbot, pode melhorar a sua comunicação digital.',
      sitemap: true,
    },
    internetProvider: {
      root: '/segmentos/provedores-internet',
      title: 'Mktzap para o segmento de Provedores de Internet - New Way',
      description:
        'Possui uma empresa no segmento de Provedores de Internet? Saiba como o Mktzap, plataforma de atendimento e chatbot, pode melhorar a sua comunicação digital.',
      sitemap: true,
    },
    retail: {
      root: '/segmentos/varejo',
      title: 'Mktzap para o segmento de Varejo - New Way',
      description:
        'Possui uma empresa no segmento de Varejo? Saiba como o Mktzap, plataforma de atendimento e chatbot, pode melhorar a sua comunicação digital.',
      sitemap: true,
    },
  },
  segmentsV1: {
    root: '/segmentos-v1',
    title: 'Principais segmentos - Mktzap',
    description:
      'Saiba como o Mktzap, plataforma de atendimento e chatbot, pode melhorar a comunicação digital de empresas dos mais diversos segmentos. Clique aqui e confira!',
    ecommerce: {
      root: '/segmentos/ecommerce',
      title: 'Mktzap para o segmento de E-commerce - New Way',
      description:
        'Possui uma empresa no segmento de E-commerce? Saiba como o Mktzap, plataforma de atendimento e chatbot, pode melhorar a sua comunicação digital.',
      sitemap: true,
    },
    financial: {
      root: '/segmentos/financeiro',
      title: 'Mktzap para o segmento de Serviços Financeiros - New Way',
      description:
        'Possui uma empresa no segmento de Serviços Financeiros? Saiba como o Mktzap, plataforma de atendimento e chatbot, pode melhorar a sua comunicação digital.',
      sitemap: true,
    },
    healthcare: {
      root: '/segmentos/saude',
      title: 'Mktzap para o segmento de Saúde - New Way',
      description:
        'Possui uma empresa no segmento de Saúde? Saiba como o Mktzap, plataforma de atendimento e chatbot, pode melhorar a sua comunicação digital.',
      sitemap: true,
    },
    internetProvider: {
      root: '/segmentos/provedores-internet',
      title: 'Mktzap para o segmento de Provedores de Internet - New Way',
      description:
        'Possui uma empresa no segmento de Provedores de Internet? Saiba como o Mktzap, plataforma de atendimento e chatbot, pode melhorar a sua comunicação digital.',
      sitemap: true,
    },
    retail: {
      root: '/segmentos/varejo',
      title: 'Mktzap para o segmento de Varejo - New Way',
      description:
        'Possui uma empresa no segmento de Varejo? Saiba como o Mktzap, plataforma de atendimento e chatbot, pode melhorar a sua comunicação digital.',
      sitemap: true,
    },
  },
  blog: {
    root: '/blog',
    title: 'Blog - New Way',
    description:
      'Tenha acesso a conteúdos exclusivos sobre estratégias e dados do mercado de atendimento ao cliente para desenvolver seu negócio no meio digital. Acesse já o nosso blog',
    sitemap: true,
    categories: {
      root: '/blog/categoria',
      title: 'Blog / Categoria - New Way',
      description:
        'Tenha acesso a conteúdos exclusivos sobre estratégias e dados do mercado de atendimento ao cliente para desenvolver seu negócio no meio digital. Acesse já o nosso blog',
      sitemap: true,
      category: {
        root: '/blog/categoria/:currentCategory?',
        title: 'Tudo sobre :currentCategory - Blog New Way',
        alternativeCategories: ['histórias de sucesso', 'novidades new way'],
        alternativeTitle: ':currentCategory - Blog New Way',
        description:
          'Confira nossos conteúdos exclusivos sobre :currentCategory e saiba como melhorar seu atendimento ao cliente dentro meio digital. Clique aqui e acesse já o nosso blog!',
        loadRDStationFormsScript: true,
      },
      loadRDStationFormsScript: true,
    },
    categoriesV1: {
      root: '/blog/categoriaV1',
      title: 'Blog / Categoria - New Way',
      description:
        'Tenha acesso a conteúdos exclusivos sobre estratégias e dados do mercado de atendimento ao cliente para desenvolver seu negócio no meio digital. Acesse já o nosso blog',
      categoryV1: {
        root: '/blog/categoriaV1/:currentCategory?',
        title: 'Tudo sobre :currentCategory - Blog New Way',
        description:
          'Confira nossos conteúdos exclusivos sobre :currentCategory e saiba como melhorar seu atendimento ao cliente dentro meio digital. Clique aqui e acesse já o nosso blog!',
      },
    },
    posts: {
      root: '/blog/post',
      title: 'Blog / Postagem - New Way',
      postV1: '/blog/postV1/:currentPost?',
      post: '/blog/post/:currentPost?',
      loadRDStationFormsScript: true,
    },
    materiais: {
      root: '/blog/materiais',
      title: 'Blog / Materiais / :currentCategory - New Way',
      altTitle: 'Blog / Materiais - New Way',
      category: '/blog/materiais/:currentCategory?',
      description:
        'Conheça os :currentCategory gratuitos que a New Way oferece e seja uma empresa referência no relacionamento com clientes e colaboradores. Clique aqui e confira!',
      sitemap: true,
      eCommerce: {
        root: '/blog/materiais/e-commerce-materiais',
        redirectTo: '/blog/materiais',
      },
      financialServices: {
        root: '/blog/materiais/servicos-financeiros-materiais',
        redirectTo: '/blog/materiais',
      },
      providers: {
        root: '/blog/materiais/provedores-de-internet-materiais',
        redirectTo: '/blog/materiais',
      },
      retail: {
        root: '/blog/materiais/varejo-materiais',
        redirectTo: '/blog/materiais',
      },
      health: {
        root: '/blog/materiais/saude-materiais',
        redirectTo: '/blog/materiais',
      },
    },
    talkSuccess: {
      root: '/blog/papo-de-sucesso',
      title: 'Blog / Papo de Sucesso - New Way',
      description:
        'Tenha acesso a conteúdos exclusivos sobre estratégias e dados do mercado de atendimento ao cliente para desenvolver seu negócio no meio digital. Acesse já o nosso blog',
    },
    loadRDStationFormsScript: true,
  },
  blogV1: {
    root: '/blogV1',
    title: 'Blog - New Way',
    description:
      'Tenha acesso a conteúdos exclusivos sobre estratégias e dados do mercado de atendimento ao cliente para desenvolver seu negócio no meio digital. Acesse já o nosso blog',
    categories: {
      root: '/blog/categoria',
      title: 'Blog / Categoria - New Way',
      description:
        'Tenha acesso a conteúdos exclusivos sobre estratégias e dados do mercado de atendimento ao cliente para desenvolver seu negócio no meio digital. Acesse já o nosso blog',
      category: {
        root: '/blog/categoria/:currentCategory?',
        title: 'Tudo sobre :currentCategory - Blog New Way',
        description:
          'Confira nossos conteúdos exclusivos sobre :currentCategory e saiba como melhorar seu atendimento ao cliente dentro meio digital. Clique aqui e acesse já o nosso blog!',
      },
    },
    posts: {
      root: '/blog/post',
      title: 'Blog / Postagem - New Way',
      post: '/blog/post/:currentPost?',
      postV2: '/blog/postV2/:currentPost?',
    },
    materiais: {
      root: '/blog/materiais',
      title: 'Blog / Materiais / :currentCategory - New Way',
      category: '/blog/materiais/:currentCategory?',
      description:
        'Tenha acesso a conteúdos exclusivos sobre estratégias e dados do mercado de atendimento ao cliente para desenvolver seu negócio no meio digital. Acesse já o nosso blog',
    },
    talkSuccess: {
      root: '/blog/papo-de-sucesso',
      title: 'Blog / Papo de Sucesso - New Way',
      description:
        'Tenha acesso a conteúdos exclusivos sobre estratégias e dados do mercado de atendimento ao cliente para desenvolver seu negócio no meio digital. Acesse já o nosso blog',
    },
  },
  cases: {
    root: '/cases',
    title: 'Cases - New Way',
  },
  privacyPolicy: {
    root: '/politica-privacidade',
    title: 'Política de privacidade - New Way',
    sitemap: true,
  },
  cookiePolicy: {
    root: '/politica-de-cookies',
    title: 'Política de cookies - New Way',
    sitemap: true,
  },
  brandGuideLine: {
    root: '/brand-guideline',
    title: 'Brand Guide Line - New Way',
  },
  afterScheduling: {
    root: '/pos-agendamento',
    title: 'Pós agendamento - New Way',
    sitemap: true,
  },
  institutional: {
    root: '/institucional',
    title: 'Quem Somos - New Way',
    sitemap: true,
    description:
      'Estamos há 12 anos no mercado de tecnologia brasileiro e internacional, marcando presença com projetos em mais de 10 países. Conheça mais sobre a New Way',
    institute: {
      root: '/institucional/instituto-novo-caminho',
      title: 'Instituto Novo Caminho - New Way',
      sitemap: true,
      description:
        'O Instituto Novo Caminho é uma organização sem fins lucrativos, com duas unidades nas cidades de Piratininga e Bauru. Conheça mais!',
    },
  },
  schedulingDemonstration: {
    root: '/agendar-demonstracao/:currentConsultant?/:calendlyUri?',
    title: 'Agendar demonstração :currentConsultantName - New Way',
    description:
      'Fale com um dos nossos consultores comerciais sem compromisso e revolucione seu relacionamento digital com o Mktzap. Converse com um especialista agora',
    redirectTo: '/pos-agendamento',
  },
  scheduling: {
    root: '/agendamento',
    title: 'Agendamento - New Way',
    description:
      'Fale com um dos nossos consultores comerciais sem compromisso e revolucione seu relacionamento digital com o Mktzap. Converse com um especialista agora',
    loadRDStationFormsScript: true,
    sitemap: true,
  },
  mktzapV1: {
    root: '/mktzap-v1',
    title: 'Mktzap - New Way',
    header: '/mktzap#header',
    attendance: '/mktzap#all-channels',
    reports: '/mktzap#reports',
    chatbot: '/mktzap#builder4bots',
    artificialIntelligence: '/mktzap#webchat',
    webchat: '/mktzap#new-chat',
    description:
      'Organize seus canais de atendimento digital em um só painel e escale seu relacionamento com a ajuda  de chatbots. Descubra tudo que o Mktzap faz pelo seu negócio',
  },
  mktzap: {
    root: '/mktzap',
    title: 'Mktzap - Plataforma de atendimento multicanal e chatbot',
    description:
      'Automatize suas interações com nossa plataforma de atendimento para WhatsApp, Instagram, Telegram, Messenger, SMS, E-mail e Webchat. Conheça o Mktzap!',
    sitemap: true,
    loadRDStationFormsScript: true,
  },
  features: {
    root: '/funcionalidades',
    title: 'Funcionalidades - New Way',
    header: '/funcionalidades#header',
    attendance: '/funcionalidades#all-channels',
    reports: '/funcionalidades#reports',
    chatbot: '/funcionalidades#builder4bots',
    artificialIntelligence: '/funcionalidades#webchat',
    webchat: '/funcionalidades#new-chat',
    description:
      'Organize seus canais de atendimento digital em um só painel e escale seu relacionamento com a ajuda  de chatbots. Descubra tudo que o Mktzap faz pelo seu negócio',
    redirectTo: '/mktzap',
  },
  releaseNotes: {
    root: '/release-notes',
    title: 'Relase Notes - New Way',
  },
  registerConfirmation: {
    root: '/cadastro-confirmado',
    title: 'Confirmação de Cadastro - New Way',
  },
  error404: {
    root: '*',
    title: 'Error 404 - New Way',
  },
  faleConosco: {
    root: '/fale-conosco',
    redirectTo: '/',
  },
  pt: {
    root: '/pt',
    redirectTo: '/',
  },
  trabalheConosco: {
    root: '/trabalhe-conosco',
    redirectTo: 'https://forms.gle/9eHeuyU6tgxYbqda6',
  },
  ptMktzap: {
    root: '/pt/mktzap',
    redirectTo: '/mktzap',
  },
  ptChatbots: {
    root: '/pt/chatbots',
    redirectTo: '/mktzap',
  },
  ptInteligenciaArtificial: {
    root: '/pt/inteligencia-artificial',
    redirectTo: '/mktzap',
  },
  whatsAppLinkGenerator: {
    root: '/gerador-de-link-whatsapp',
    title: 'Gerador de link para WhatsApp Business - New Way',
    description:
      'O gerador de link para WhatsApp Business cria uma URL direto para seu número comercial para compartilhar com seus clientes e agilizar o seu atendimento.',
    sitemap: true,
  },
  canais: {
    sitemap: true,
    whatsApp: {
      root: '/canais/whatsapp',
      redirectTo: '/canais/whatsapp-api',
    },
    whatsAppAPI: {
      root: '/canais/whatsapp-api',
      redirectTo: '/',
    },
    whatsAppAPIF: {
      root: '/f/canais/whatsapp-api',
      title: 'Mktzap - API Oficial WhatsApp Business ',
      description:
        'Canal oficial que além de segurança, traz para a sua empresa um menor risco de banimento, chatbots ilimitados, selo de verificação e muito mais.',
    },
  },
};

function transformProperties(obj) {
  return {
    title: obj.title,
    description: obj.description,
    thumbnail: obj.thumbnail,
    redirectTo: obj.redirectTo,
    loadRDStationFormsScript: obj.loadRDStationFormsScript,
  };
}

export { transformProperties };

export default links;
