function HotjarLoader() {
  const content = `<!-- Hotjar Tracking Code for gruponewway.com.br -->
          (function(h,o,t,j,a,r){
            h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
            h._hjSettings={hjid:1742572,hjsv:6};
            a=o.getElementsByTagName('head')[0];
            r=o.createElement('script');r.async=1;
            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
            a.appendChild(r);
          })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`;
  const script =
    document.getElementById('hotjar-loader') ??
    document.createElement('script');
  script.id = 'hotjar-loader';
  script.innerHTML = content;
  script.async = true;
  if (!document.getElementById('hotjar-loader'))
    document.getElementsByTagName('body')[0].appendChild(script);
}

export default HotjarLoader;
