import { faChevronDown, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactLoading from 'react-loading';
import { Link, useHistory } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Navbar from 'react-bootstrap/Navbar';
import setAcessibilityFocus from '../../../utils/acessibilityFocusMainContent';
import NavDropdownI18n from '../NavDropdownI18n';

import links from '../../../routes/links';

import { nwLogoMini } from '../../../assets/images/commons';

import { fadeDuration } from '../../../constants';

import { pushDataLayer } from '../../../utils/pushDataLayer';
import slugString from '../../../utils/slugString';
import MenuCollapse from '../MenuCollapse';
import './styles.css';

const getNavDropdownTitle = (title) => (
  <>
    {title}
    <FontAwesomeIcon icon={faChevronDown} />
  </>
);

function MenuBlog({ menu, refParent }) {
  const { t } = useTranslation();
  const history = useHistory();

  const [pathname, SetPathname] = useState(history?.location.pathname ?? '');

  useEffect(() => {
    return history?.listen?.((location) => {
      SetPathname(location.pathname);
    });
  }, []);

  const getGtmLabel = (item, subitem) => {
    const subItemName = subitem?.description
      ? `_${slugString(subitem.description)}`
      : '';
    return `${slugString(item.description)}${subItemName}`;
  };

  const dataLayerCategory = 'menu-blog';

  const dropdownRefs = useRef([]);

  const acessibilityFocus = useCallback((e) => {
    e.preventDefault();
    setAcessibilityFocus();
  }, []);

  const pushDataWithPathName = useCallback((menu, path) => {
    pushDataLayer(dataLayerCategory, menu);
    SetPathname(path);
  }, []);

  const getPushDataWithPathName = (menu, path) => () =>
    pushDataWithPathName(menu, path);

  const pushDataLayerWithHistory = useCallback((e, ref, label = '') => {
    e.preventDefault();
    pushDataLayer(dataLayerCategory, label ? label : e.target.dataset.gtmLabel);
    history.push(ref);
  }, []);

  const getPushDataLayerWithHistory = (ref, label) => (e) =>
    pushDataLayerWithHistory(e, ref, label);

  const pushDataLayerWithDropdownRef = useCallback((e, dropdownRef, label) => {
    if (e.target === dropdownRef) {
      e.preventDefault();
      pushDataLayer(dataLayerCategory, label);
    }
  });

  const getPushDataLayerWithDropdownRef = (dropdownRef, key, label) => (e) =>
    pushDataLayerWithDropdownRef(e, dropdownRef.current[key].firstChild, label);

  return (
    <MenuCollapse refParent={refParent} className="navbar-blog">
      <Container>
        <h2 className="hidden">Navegação do site</h2>
        <Nav.Link
          href="#"
          onClick={acessibilityFocus}
          className="hide-visibility"
          id="go-to-main-content"
        >
          {t('common:menuGoToMainContent')}
        </Nav.Link>
        <Navbar.Brand className="">
          <div className="blog-brand-container">
            <span className="hide-visibility" id="logo-link">
              {t('common:menuHome')}
            </span>
            <Link
              to={links.home.root}
              onClick={getPushDataWithPathName('home', links.home.root)}
            >
              <figure>
                <img src={nwLogoMini} alt="" />
              </figure>
            </Link>
            <Link
              to={links.blog.root}
              onClick={getPushDataWithPathName('blog', links.blog.root)}
            >
              <span>{t('home:blog.title')}</span>
            </Link>
          </div>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ml-auto">
            {!menu ? (
              <ReactLoading
                type="bubbles"
                color="#5a6570"
                className="loading-menu"
              />
            ) : (
              <>
                {menu.items &&
                  menu.items.map((item, key) =>
                    item.items && item.items.length ? (
                      <div
                        key={item.id}
                        data-aos="fade-in"
                        data-aos-delay="50"
                        data-aos-duration={fadeDuration()}
                        data-aos-offset="0"
                      >
                        <NavDropdown
                          title={getNavDropdownTitle(item.name)}
                          className="nav-item"
                          ref={(el) => {
                            dropdownRefs.current[key] = el;
                          }}
                          active={
                            item.items.filter((subitem) =>
                              pathname.includes(subitem.url)
                            ).length > 0
                          }
                          onClick={getPushDataLayerWithDropdownRef(
                            dropdownRefs,
                            key,
                            item.description
                          )}
                        >
                          {item.items.map((subitem) => (
                            <NavDropdown.Item
                              key={subitem.id}
                              href={subitem.url}
                              data-gtm-label={getGtmLabel(item, subitem)}
                              onClick={getPushDataLayerWithHistory(subitem.url)}
                              active={new RegExp(`${subitem.url}$`).test(
                                pathname
                              )}
                            >
                              {subitem.name}
                            </NavDropdown.Item>
                          ))}
                        </NavDropdown>
                      </div>
                    ) : (
                      <div
                        key={item.id}
                        data-aos="fade-in"
                        data-aos-delay="50"
                        data-aos-duration={fadeDuration()}
                        data-aos-offset="0"
                        className="nav-item"
                      >
                        <Nav.Link
                          className="nav-item"
                          href={`${item.url}`}
                          data-gtm-label={getGtmLabel(item)}
                          onClick={getPushDataLayerWithHistory(item.url)}
                          active={new RegExp(`${item.url}$`).test(pathname)}
                        >
                          {item.name}
                        </Nav.Link>
                      </div>
                    )
                  )}
                <NavDropdownI18n
                  getNavDropdownTitle={getNavDropdownTitle}
                  gtmCategory="menu-blog"
                />
              </>
            )}
            <Nav.Link
              className="nav-item"
              href={links.blog.categories.root}
              onClick={getPushDataLayerWithHistory(
                links.blog.categories.root,
                'pesquisar'
              )}
              active={new RegExp(`${links.blog.categories.root}$`).test(
                pathname
              )}
            >
              <span className="hide-visibility">
                {t('common:search.title')}
              </span>
              <FontAwesomeIcon icon={faSearch} />
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </MenuCollapse>
  );
}

export default MenuBlog;
