import { useEffect, useCallback, useState } from 'react';
import { Cookie } from '../../index';
import HotjarLoader from '../../../../utils/hotjarLoader';

const hotjarCookies = [
  '_hjAbsoluteSessionInProgress',
  '_hjIncludedInPageviewSample',
  '_hjIncludedInSessionSample',
  '_hjid',
];

function Hotjar({ active }) {
  const cookies = hotjarCookies.map((name) => new Cookie(name, null));
  const [hotjarLoaded, setHotjarLoaded] = useState(false);
  const [isConsent, setIsConsent] = useState(active);

  const removeHotjarCookies = useCallback(() => {
    if (!active) {
      cookies.forEach((cookie) => {
        cookie.get() && cookie.del();
      });
    }
    if (isConsent !== active) {
      setIsConsent(active);
      global.__CLIENT__ && window.location.reload();
    }
  }, [active, cookies, isConsent, setIsConsent]);

  useEffect(() => {
    removeHotjarCookies();
    if (!hotjarLoaded && isConsent) {
      HotjarLoader();
      setHotjarLoaded(true);
    }
  }, [removeHotjarCookies, hotjarLoaded, setHotjarLoaded, isConsent]);

  return active;
}

export default Hotjar;
