import { lazy } from 'react';
import links, { transformProperties } from './links';

const Home = lazy(() => import('../pages/Home'));
const HomeV2 = lazy(() => import('../pages/HomeV2'));
const Plans = lazy(() => import('../pages/Plans'));
const Segments = lazy(() => import('../pages/Segments'));
const SegmentsV2 = lazy(() => import('../pages/SegmentsV2'));
const Ecommerce = lazy(() => import('../pages/Segments/Ecommerce'));
const Financial = lazy(() => import('../pages/Segments/Financial'));
const Retail = lazy(() => import('../pages/Segments/Retail'));
const Healthcare = lazy(() => import('../pages/Segments/Healthcare'));
const InternetProvider = lazy(() =>
  import('../pages/Segments/InternetProvider')
);
const Blog = lazy(() => import('../pages/Blog/csr'));
const BlogV2 = lazy(() => import('../pages/BlogV2/csr'));
const Institutional = lazy(() => import('../pages/Institutional'));
const NewWayInstitute = lazy(() => import('../pages/NewWayInstitute'));
const Scheduling = lazy(() => import('../pages/Scheduling'));
const Mktzap = lazy(() => import('../pages/Mktzap'));
const MktzapV2 = lazy(() => import('../pages/MktzapV2'));
const Features = lazy(() => import('../pages/Features'));
const Materials = lazy(() => import('../pages/Blog/MaterialsPage'));
const Post = lazy(() => import('../pages/Blog/PostsPage/PostPage/csr'));
const PostV2 = lazy(() => import('../pages/BlogV2/PostsPage/PostPageV2/csr'));
const CategoriesPage = lazy(() => import('../pages/Blog/CategoriesPage/csr'));
const AfterScheduling = lazy(() => import('../pages/AfterScheduling'));
const PrivacyPolicy = lazy(() => import('../pages/PrivacyPolicy'));
const CookiePolicy = lazy(() => import('../pages/CookiePolicy'));
const BrandGuideLine = lazy(() => import('../pages/BrandGuideLine'));
const ReleaseNotes = lazy(() => import('../pages/ReleaseNotes'));
const RegisterConfirmation = lazy(() =>
  import('../pages/RegisterConfirmation')
);
const Error404 = lazy(() => import('../pages/Error404'));
const RedirectPage = lazy(() => import('../pages/Redirect'));
const CategoriesPageV2 = lazy(() =>
  import('../pages/BlogV2/CategoriesPageV2/csr')
);
const WhatsAppLinkGeneratorPage = lazy(() =>
  import('../pages/WhatsAppLinkGenerator')
);
const WhatsApp = lazy(() => import('../pages/WhatsApp'));
const Contracts = lazy(() => import('../pages/Contracts'));

const routes = [
  {
    path: links.home.root,
    Component: HomeV2,
    ...transformProperties(links.home),
  },
  {
    path: links.homeV1.root,
    Component: Home,
    ...transformProperties(links.homeV1),
  },
  {
    path: links.plans.root,
    Component: Plans,
    ...transformProperties(links.plans),
  },
  {
    path: links.segmentsV1.root,
    Component: Segments,
    ...transformProperties(links.segmentsV1),
  },
  {
    path: links.segments.root,
    Component: SegmentsV2,
    ...transformProperties(links.segments),
  },
  {
    path: links.segments.ecommerce.root,
    Component: Ecommerce,
    ...transformProperties(links.segments.ecommerce),
  },
  {
    path: links.segments.financial.root,
    Component: Financial,
    ...transformProperties(links.segments.financial),
  },
  {
    path: links.segments.retail.root,
    Component: Retail,
    ...transformProperties(links.segments.retail),
  },
  {
    path: links.segments.healthcare.root,
    Component: Healthcare,
    ...transformProperties(links.segments.healthcare),
  },
  {
    path: links.segments.internetProvider.root,
    Component: InternetProvider,
    exact: true,
    ...transformProperties(links.segments.internetProvider),
  },
  {
    path: links.blogV1.root,
    Component: Blog,
    ...transformProperties(links.blogV1),
  },
  {
    path: links.blog.root,
    Component: BlogV2,
    ...transformProperties(links.blog),
  },
  {
    path: links.institutional.root,
    Component: Institutional,
    ...transformProperties(links.institutional),
  },
  {
    path: links.institutional.institute.root,
    Component: NewWayInstitute,
    ...transformProperties(links.institutional.institute),
  },
  {
    path: links.scheduling.root,
    Component: Scheduling,
    ...transformProperties(links.scheduling),
  },
  {
    path: links.mktzap.root,
    Component: MktzapV2,
    exact: true,
    ...transformProperties(links.mktzap),
  },
  {
    path: links.mktzapV1.root,
    Component: Mktzap,
    exact: true,
    ...transformProperties(links.mktzapV1),
  },
  {
    path: links.features.root,
    Component: Features,
    exact: false,
    ...transformProperties(links.features),
  },
  {
    path: `${links.blog.materiais.eCommerce.root}`,
    Component: RedirectPage,
    exact: true,
    ...transformProperties(links.blog.materiais.eCommerce),
  },
  {
    path: `${links.blog.materiais.financialServices.root}`,
    Component: RedirectPage,
    exact: true,
    ...transformProperties(links.blog.materiais.financialServices),
  },
  {
    path: `${links.blog.materiais.providers.root}`,
    Component: RedirectPage,
    exact: true,
    ...transformProperties(links.blog.materiais.providers),
  },
  {
    path: `${links.blog.materiais.retail.root}`,
    Component: RedirectPage,
    exact: true,
    ...transformProperties(links.blog.materiais.retail),
  },
  {
    path: `${links.blog.materiais.health.root}`,
    Component: RedirectPage,
    exact: true,
    ...transformProperties(links.blog.materiais.health),
  },
  {
    path: `${links.blog.materiais.category}`,
    Component: Materials,
    exact: false,
    ...transformProperties(links.blog.materiais),
  },
  {
    path: `${links.blog.posts.postV1}`,
    Component: Post,
    exact: true,
    ...transformProperties(links.blog.posts),
  },
  {
    path: `${links.blog.posts.post}`,
    Component: PostV2,
    exact: true,
    ...transformProperties(links.blog.posts),
  },
  {
    path: `${links.blog.categoriesV1.categoryV1.root}`,
    Component: CategoriesPage,
    exact: false,
    ...transformProperties(links.blog.categoriesV1.categoryV1),
  },
  {
    path: `${links.blog.categories.category.root}`,
    Component: CategoriesPageV2,
    exact: false,
    ...transformProperties(links.blog.categories.category),
  },
  {
    path: `${links.afterScheduling.root}`,
    Component: AfterScheduling,
    exact: false,
    ...transformProperties(links.afterScheduling),
  },
  {
    path: `${links.schedulingDemonstration.root}`,
    Component: RedirectPage,
    ...transformProperties(links.schedulingDemonstration),
  },
  {
    path: `${links.privacyPolicy.root}`,
    Component: PrivacyPolicy,
    ...transformProperties(links.privacyPolicy),
  },
  {
    path: `${links.cookiePolicy.root}`,
    Component: CookiePolicy,
    ...transformProperties(links.cookiePolicy),
  },
  {
    path: `${links.brandGuideLine.root}`,
    Component: BrandGuideLine,
    ...transformProperties(links.brandGuideLine),
  },
  {
    path: `${links.releaseNotes.root}`,
    Component: ReleaseNotes,
    ...transformProperties(links.releaseNotes),
  },
  {
    path: `${links.registerConfirmation.root}`,
    Component: RegisterConfirmation,
    ...transformProperties(links.registerConfirmation),
  },
  {
    path: `${links.faleConosco.root}`,
    Component: RedirectPage,
    ...transformProperties(links.faleConosco),
  },
  {
    path: `${links.pt.root}`,
    Component: RedirectPage,
    ...transformProperties(links.pt),
  },
  {
    path: `${links.trabalheConosco.root}`,
    Component: RedirectPage,
    ...transformProperties(links.trabalheConosco),
  },
  {
    path: `${links.ptMktzap.root}`,
    Component: RedirectPage,
    ...transformProperties(links.ptMktzap),
  },
  {
    path: `${links.ptChatbots.root}`,
    Component: RedirectPage,
    ...transformProperties(links.ptChatbots),
  },
  {
    path: `${links.ptChatbots.root}`,
    Component: RedirectPage,
    ...transformProperties(links.ptChatbots),
  },
  {
    path: `${links.ptInteligenciaArtificial.root}`,
    Component: RedirectPage,
    ...transformProperties(links.ptInteligenciaArtificial),
  },
  {
    path: `${links.whatsAppLinkGenerator.root}`,
    Component: WhatsAppLinkGeneratorPage,
    ...transformProperties(links.whatsAppLinkGenerator),
  },
  {
    path: `${links.canais.whatsApp.root}`,
    Component: RedirectPage,
    ...transformProperties(links.canais.whatsApp),
  },
  {
    path: `${links.canais.whatsAppAPI.root}`,
    Component: RedirectPage,
    ...transformProperties(links.canais.whatsAppAPI),
  },
  {
    path: `${links.canais.whatsAppAPIF.root}`,
    Component: WhatsApp,
    ...transformProperties(links.canais.whatsAppAPIF),
  },
  {
    path: links.contracts.root,
    Component: Contracts,
    ...transformProperties(links.contracts),
  },
];

const route404 = {
  path: `${links.error404.root}`,
  Component: Error404,
  ...transformProperties(links.error404),
};

routes.push(route404);

const config = {
  routes,
  defaultRouteTitle: links.defaultTitle,
  defaultRouteDescription: links.defaultDescription,
  defaultRouteThumbnail: links.defaultThumbnail,
};

export default config;
