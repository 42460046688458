import React from 'react';
import XHRInterceptorContext from '../../contexts/XHRInterceptor';
import XHRInterceptorInit from '../../contexts/XHRInterceptor/init';

export default function XHRInterceptorProvider({ children }) {
  const xhrInterceptorInit = XHRInterceptorInit();

  return (
    <XHRInterceptorContext.Provider value={xhrInterceptorInit}>
      {children}
    </XHRInterceptorContext.Provider>
  );
}
