/* eslint-disable no-underscore-dangle */
/* eslint-disable global-require */
import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import Menu from '../Base/Menu';
import links from '../../routes/links';
import useMenuApp from '../../hooks/UseMenuApp';
import useWindowSize from '../../hooks/UseWindowSize';
import { desktopSize } from '../../constants';

import './styles.css';

function MenuApp({ pageProps }) {
  const history = useHistory();
  const refMenu = useRef();
  const { change } = useMenuApp();
  const { height: windowHeight, width: windowWidth } = useWindowSize();

  let MenuBlog;
  if (global.__CLIENT__) MenuBlog = require('../Base/MenuBlog/csr').default;
  else MenuBlog = require('../Base/MenuBlog').default;

  const menuPaths = [
    {
      path: links.home.root,
      Menu: Menu,
      test: null,
      menuOffsetHeight: 75,
    },
    {
      path: links.blogV1.root,
      Menu: MenuBlog,
      test: new RegExp(`${links.blogV1.root}$`, 'i'),
      menuOffsetHeight: 20,
    },
    {
      path: links.blog.root,
      Menu: MenuBlog,
      test: new RegExp(`${links.blog.root}$`, 'i'),
      menuOffsetHeight: 20,
    },
  ];
  const defaultMenu = menuPaths[0];

  // pegando propriedades de Server Side para o menu
  const menusProps = pageProps?.filter((pageProp) =>
    menuPaths.filter((menuPath) => menuPath.test?.test(pageProp.path))
  );

  const props =
    menusProps && menusProps.length
      ? menusProps
          .map((menuProps) => menuProps.props)
          .reduce((obj, menuProps) => ({
            ...obj,
            ...menuProps,
          }))
      : {};

  const identifyMenu = (location) => {
    const paths =
      location.pathname.split('/').filter((item) => item !== '') ?? [];
    const menus = paths
      .map((path) =>
        menuPaths.filter((menuPath) => menuPath.test?.test(`/${path}`))
      )
      .reduce((acc, val) => acc.concat(val), []);
    return menus.length && menus[menus.length - 1];
  };

  const getComponentMenu = (menu) =>
    menu ? (
      <menu.Menu menu={props.menuBlog} refParent={refMenu} />
    ) : (
      <defaultMenu.Menu refParent={refMenu} />
    );

  const getMenuHeight = (menu) =>
    menu ? menu.menuOffsetHeight : defaultMenu.menuOffsetHeight;

  const startMenu = identifyMenu(history?.location);

  const [menuComponent, setMenuComponent] = useState(
    getComponentMenu(startMenu)
  );

  const [menuOffsetHeight, setMenuOffsetHeight] = useState(
    getMenuHeight(startMenu)
  );

  const changeMenuHeightCB = useCallback(() => {
    const menuHeight = refMenu.current?.offsetHeight ?? 0;
    const totalMenuHeight = menuHeight + menuOffsetHeight;
    const isWindowInitialLoading = !windowHeight;
    const isDesktop = windowWidth >= desktopSize;
    const isWindowInitialLoadingAndIsDesktop =
      isWindowInitialLoading && isDesktop;
    const isTotalMenuHeightLessThanHalfOfWindowHeight =
      totalMenuHeight < windowHeight / 2;
    if (
      isWindowInitialLoadingAndIsDesktop ||
      isTotalMenuHeightLessThanHalfOfWindowHeight
    )
      change({ height: totalMenuHeight });
  }, [menuOffsetHeight, windowHeight]);

  const updateStateFromIdentifyMenu = useCallback(
    (location) => {
      const menu = identifyMenu(location);
      setMenuComponent(getComponentMenu(menu));
      setMenuOffsetHeight(getMenuHeight(menu));
      changeMenuHeightCB();
    },
    [setMenuComponent, setMenuOffsetHeight, changeMenuHeightCB]
  );

  const resetMenuAppContext = () => change({});

  useEffect(() => {
    const unlisten = history?.listen?.((location) => {
      updateStateFromIdentifyMenu(location);
    });
    return () => {
      unlisten();
      resetMenuAppContext();
    };
  }, []);

  useEffect(() => {
    changeMenuHeightCB();
  }, [windowWidth, changeMenuHeightCB]);

  return (
    <header
      id="menu-section"
      className="navbar-wrapper animate__animated animate__fadeInDown animate__faster"
      ref={refMenu}
    >
      {menuComponent}
    </header>
  );
}

export default MenuApp;
