import {
  RDStationLoaderSingletonName,
  RDStationFormsScriptSingletonName,
  RDStationFormsScript,
  RDStationLoaderScript,
} from './addRDStationScript';

export default function remRDStationScript() {
  let div = document.getElementById(RDStationLoaderSingletonName);
  if (div) {
    while (div.nextSibling)
      if (
        /d335luupugsy2.cloudfront.net/.test(div.nextSibling.src) ||
        div.nextSibling.id === 'rd_tmgr'
      )
        div.nextSibling.remove();
      else div = div.nextSibling;
    document.getElementById(RDStationLoaderSingletonName).remove();
  }
  document.getElementById(RDStationFormsScriptSingletonName)?.remove?.();
  const nodesPopup = document.querySelectorAll('body > div[id^="popup"]');
  nodesPopup?.forEach((nodePopup) => nodePopup.remove());
  const keys = Object.keys(window).filter((item) => /^RD/.test(item));
  keys.forEach((el) => {
    window[el] = undefined;
  });
  RDStationFormsScript.clear();
  RDStationLoaderScript.clear();
}
