import { useEffect, useCallback } from 'react';
import { Cookie, expiresIn } from '../../index';
import { useRDStationLoaderListener } from '../../../RDStationLoader';

const rdCookies = ['_form_fields'];

function RDStationUncategorized({ active }) {
  const cookies = rdCookies.map((name) => new Cookie(name, null));
  const cookieCategory = new Cookie('cookieconsent_status_UNCATEGORIZED', null);

  const changeRDCookies = useCallback(() => {
    const status = active ? 'ALLOW' : 'DENY';
    cookieCategory.get() !== status &&
      cookieCategory.save(status, { expires: expiresIn });
    if (!active)
      cookies.forEach((cookie) => {
        cookie.get() && cookie.del();
      });
  }, [active, cookies, cookieCategory]);

  const scriptLoaded = useRDStationLoaderListener();

  if (
    scriptLoaded &&
    window.RDCookieControl &&
    window.RDCookieControl.functional
  )
    window.RDCookieControl.functional.track = active;

  useEffect(() => {
    changeRDCookies();
  }, [changeRDCookies]);

  return active;
}

export default RDStationUncategorized;
