import Accessibilities from '@gruponewway/aquisicao-accessibilities';
import '@gruponewway/aquisicao-accessibilities/dist/index.css';
import React from 'react';
import { useTranslation } from 'react-i18next';
import interopDefault from '../../utils/interopDefault';

const InteropAccessibilities = interopDefault(Accessibilities);

const headerSelector = 'header#menu-section';
const mainSelector = 'main#main-section';
const footerSelector = 'footer#footer-section';

const headerTargets = [
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'h7',
  'p',
  'span:not(.navbar-toggler-icon, .cta-scheduling-text)',
  'a:not(.cta-scheduling-text)',
  'li',
  'label',
  'input',
];

const mainTargets = [
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'p:not(.chat-message-text, .ignore-accessibility-size)',
  'select',
  'option',
  'h2 span',
  '[role="tablist"] [role="tab"] span',
  'th',
];

const footerTargets = [
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'h7',
  'p',
  'span',
  'li',
  'a',
];

const targets = [
  ...headerTargets.map((val) => `${headerSelector} ${val}`),
  ...mainTargets.map((val) => `${mainSelector} ${val}`),
  ...footerTargets.map((val) => `${footerSelector} ${val}`),
];

function MyAccessibilities() {
  const { t } = useTranslation();
  return (
    <InteropAccessibilities
      targets={targets}
      showAccessibilityOptions={t(
        'common:accessibility.showAccessibilityOptions'
      )}
      hideAccessibilityOptions={t(
        'common:accessibility.hideAccessibilityOptions'
      )}
      changeFontUp={t('common:accessibility.changeFontUp')}
      changeFontDown={t('common:accessibility.changeFontDown')}
      changeContrast={t('common:accessibility.changeContrast')}
      draggableTitle={t('common:accessibility.draggableTitle')}
    />
  );
}

export default MyAccessibilities;
