import { useEffect, useCallback, useState } from 'react';
import TagManager from 'react-gtm-module';
import { Cookie } from '../../index';

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID,
};

const tagManagerAgencyArgs = {
  gtmId: process.env.REACT_APP_AGENCY_GTM_ID,
};

const googleCookies = [
  '_ga',
  '_gid',
  '_gat',
  '__utmv',
  '__utmz',
  '__utmc',
  '__utmb',
  '__utmt',
  '__utma',
  `_gat_${process.env.REACT_APP_GA_ID}`,
  `_gat_${process.env.REACT_APP_AGENCY_GA_ID}`,
];

function GoogleAnalytics({ active }) {
  const cookies = googleCookies.map((name) => new Cookie(name, null));
  const [tagManagerLoaded, setTagManagerLoaded] = useState(false);
  const [isConsent, setIsConsent] = useState(active);

  const removeGoogleCookies = useCallback(() => {
    if (global.__CLIENT__) {
      window[`ga-disable-${process.env.REACT_APP_GA_ID}`] = !active;
      window[`ga-disable-${process.env.REACT_APP_AGENCY_GA_ID}`] = !active;
    }
    if (!active) {
      if (window.ga) window.ga('remove');
      cookies.forEach((cookie) => {
        cookie.get() && cookie.del();
      });
    }
    if (isConsent !== active) {
      setIsConsent(active);
      global.__CLIENT__ && window.location.reload();
    }
  }, [active, cookies, isConsent, setIsConsent]);

  useEffect(() => {
    removeGoogleCookies();
    if (!tagManagerLoaded) {
      TagManager.initialize(tagManagerArgs);
      TagManager.initialize(tagManagerAgencyArgs);
      setTagManagerLoaded(true);
    }
  }, [removeGoogleCookies, tagManagerLoaded, setTagManagerLoaded]);

  return active;
}

export default GoogleAnalytics;
