import { useState, useCallback, useEffect } from 'react';

function useLoginAccess() {
  const [permitted, setPermitted] = useState(false);

  const changeLoginAccess = useCallback(
    (event) => {
      setPermitted(event.detail?.permitted);
    },
    [setPermitted]
  );

  useEffect(() => {
    window.addEventListener('change-login-access', changeLoginAccess);
    return () => {
      window.removeEventListener('change-login-access', changeLoginAccess);
    };
  }, [changeLoginAccess]);

  return permitted;
}

export default useLoginAccess;
