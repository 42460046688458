export default function removeLastSlash(str) {
  if (typeof str !== 'string') {
    throw new Error('Argument should be a string');
  }

  if (str.endsWith('/')) {
    return str.substring(0, str.length - 1);
  }
  return str;
}
