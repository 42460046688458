import { Cookie, useCookie } from '../../components/GDPR';
import UseCookieOptions from '../UseCookieOptions';

export class UserIdCookie extends Cookie {
  constructor() {
    super('nwsite-user-id', null);
  }
}

export default function useUserIdCookie() {
  const option = UseCookieOptions();
  return useCookie(new UserIdCookie(), option);
}
