/* eslint-disable no-underscore-dangle */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import XHR from 'i18next-http-backend';
import dayjs from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/pt-br';
import ptBR from './languages/pt-BR';
import en from './languages/en';
import test from './languages/test';

const defaultLanguage = 'pt-BR';

const options = {
  order: ['querystring', 'localStorage'],
  lookupQuerystring: 'lng',
  lookupLocalStorage: 'i18nextLng',
  caches: ['localStorage'],
};

const locales = ['en', 'pt-BR'];
const resources = {
  ...ptBR,
  ...en,
};

let config = {
  locales,
  resources,
  fallbackLng: defaultLanguage,
  detection: options,

  debug: false,

  interpolation: {
    escapeValue: false, // react already safes from xss
  },
  react: {
    useSuspense: false,
  },
};

if (global.__TEST__) {
  config = {
    ...config,
    lng: 'test',
    resources: { ...resources, ...test },
    fallbackLng: undefined,
  };
  config.locales.push('test');
}

i18n
  .use(XHR)
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init(config);

const setLangDateJS = () => {
  let dateLng;
  const language = i18n.language ?? '';
  const currentLanguage = locales.includes(language)
    ? language
    : defaultLanguage;
  if (global.__TEST__) dateLng = 'pt-br';
  else dateLng = currentLanguage.toLocaleLowerCase();
  try {
    dayjs.locale(dateLng);
  } catch (e) {
    console.error(e);
  }
};

setLangDateJS();

const getLanguage = () => {
  const lng =
    i18n.language ||
    (typeof window !== 'undefined' && window.localStorage.i18nextLng) ||
    defaultLanguage;
  return locales.includes(lng) ? lng : defaultLanguage;
};

const changeLanguage = (lng) => {
  if (typeof window !== 'undefined' && window.dispatchEvent)
    window.dispatchEvent(
      new CustomEvent('change-language', {
        detail: lng,
      })
    );
  i18n.changeLanguage(lng);
  setLangDateJS();
};

export { getLanguage, locales, setLangDateJS, changeLanguage, defaultLanguage };
export default i18n;
