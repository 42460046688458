const slugString = (value, defaultValue) => {
  if (value && typeof value === 'string')
    return value
      .toLowerCase()
      .replace(/\s/g, '-')
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
  return defaultValue;
};

export default slugString;
