import React, { useState, useCallback } from 'react';
import RDStationLoaderContext from '../../contexts/RDStationLoader';

export default function RDStationLoaderProvider({ children }) {
  const [value, setValue] = useState({
    scripts: [],
    push: (newValue) => {
      waitLoadCallback(newValue);
    },
    clear: () =>
      setValue((prev) => ({
        scripts: [],
        clear: prev.clear,
        push: prev.push,
        pushLoadedScript: prev.pushLoadedScript,
      })),
    pushLoadedScript: (newValue) =>
      setValue((prev) => ({
        scripts: [...prev.scripts, newValue],
        clear: prev.clear,
        push: prev.push,
        pushLoadedScript: prev.pushLoadedScript,
      })),
  });

  const waitLoadCallback = useCallback(
    async (singletonName) => {
      let timeout = 0;
      do {
        // eslint-disable-next-line no-await-in-loop
        await new Promise((resolve) => setTimeout(resolve, 1000));
        timeout += 1;
      } while (window[singletonName] === undefined && timeout < 5);
      if (window[singletonName] !== undefined)
        value.pushLoadedScript(singletonName);
    },
    [value]
  );

  return (
    <RDStationLoaderContext.Provider value={value}>
      {children}
    </RDStationLoaderContext.Provider>
  );
}
