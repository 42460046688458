import React from 'react';
import { RequiredCookies, RequiredCookie } from '../index';
import RDStationEssential from './RDStationEssential';
import NwSiteUserId from './NwSiteUserId';

function MyRequiredCookies({ cookieOptions }) {
  return (
    <RequiredCookies cookieOptions={cookieOptions}>
      {({ active, setActive }) => {
        const requiredCookie = new RequiredCookie(null);
        const value = requiredCookie.get();
        return (
          <>
            <RDStationEssential
              active={active || value === 'init'}
              setActive={setActive}
              value={requiredCookie.get()}
            />
            <NwSiteUserId
              active={active || value === 'init'}
              setActive={setActive}
              value={requiredCookie.get()}
            />
          </>
        );
      }}
    </RequiredCookies>
  );
}

export default MyRequiredCookies;
