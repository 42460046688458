import { useCallback, useEffect, useState } from 'react';
import { useRDStationLoaderListener } from '../../../RDStationLoader';
import { Cookie, expiresIn } from '../../index';

const rdCookies = ['__trf.src', 'rdtrk'];

function RDStationPersonalization({ active }) {
  const cookies = rdCookies.map((name) => new Cookie(name, null));
  const cookieCategory = new Cookie(
    'cookieconsent_status_PERSONALIZATION',
    null
  );
  const [isConsent, setIsConsent] = useState(active);

  const changeRDCookies = useCallback(() => {
    const status = active ? 'ALLOW' : 'DENY';
    cookieCategory.get() !== status &&
      cookieCategory.save(status, { expires: expiresIn });
    if (!active)
      cookies.forEach(async (cookie) => {
        let timeout = 0;
        do {
          await new Promise((resolve) => setTimeout(resolve, 1000));
          timeout += 1;
        } while (cookie.get() === undefined && timeout < 5);
        cookie.get() && cookie.del();
      });
    if (isConsent !== active) {
      setIsConsent(active);
      global.__CLIENT__ && window.location.reload();
    }
  }, [active, cookies, cookieCategory, isConsent]);

  const scriptLoaded = useRDStationLoaderListener();

  scriptLoaded && changeRDCookies();

  useEffect(() => {
    changeRDCookies();
  }, [changeRDCookies]);

  return active;
}

export default RDStationPersonalization;
