import React from 'react';

export default function IconBrasil() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 71 71"
    >
      <defs>
        <filter
          id="Ellipse_45"
          x="0"
          y="0"
          width="71"
          height="71"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="3" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="3" result="blur" />
          <feFlood floodOpacity="0.506" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g id="Group_646" data-name="Group 646" transform="translate(-506 -4535)">
        <g transform="matrix(1, 0, 0, 1, 506, 4535)" filter="url(#Ellipse_45)">
          <circle
            id="Ellipse_45-2"
            data-name="Ellipse 45"
            cx="26.5"
            cy="26.5"
            r="26.5"
            transform="translate(9 6)"
            fill="#fff"
          />
        </g>
        <circle
          id="Ellipse_35"
          data-name="Ellipse 35"
          cx="26.907"
          cy="26.907"
          r="26.907"
          transform="translate(515.234 4541.202)"
          fill="#00923a"
        />
        <path
          id="Path_410"
          data-name="Path 410"
          d="M540.459,11187.292l-25.657-16.354,25.657-16.354,25.669,16.354Z"
          transform="translate(1.676 -6602.828)"
          fill="#f5d600"
        />
        <g
          id="Group_310"
          data-name="Group 310"
          transform="translate(531.348 4557.315)"
        >
          <circle
            id="Ellipse_36"
            data-name="Ellipse 36"
            cx="10.794"
            cy="10.794"
            r="10.794"
            fill="#002776"
          />
        </g>
        <g
          id="Group_311"
          data-name="Group 311"
          transform="translate(531.686 4563.481)"
        >
          <path
            id="Path_411"
            data-name="Path 411"
            d="M530.583,11166.579a11.66,11.66,0,0,0-.574,1.654,24.68,24.68,0,0,1,20.755,5.945,10.469,10.469,0,0,0,.387-1.7A26.105,26.105,0,0,0,530.583,11166.579Z"
            transform="translate(-530.009 -11166.31)"
            fill="#fff"
          />
        </g>
        <path
          id="Path_412"
          data-name="Path 412"
          d="M531.657,11167.062a.363.363,0,0,0-.1-.162.338.338,0,0,0-.145-.1.44.44,0,0,0-.2-.042.431.431,0,0,0-.2.042.325.325,0,0,0-.152.1.438.438,0,0,0-.1.162.761.761,0,0,0,0,.423.5.5,0,0,0,.1.163.469.469,0,0,0,.152.108.52.52,0,0,0,.2.036.531.531,0,0,0,.2-.036.541.541,0,0,0,.151-.108.391.391,0,0,0,.09-.163.639.639,0,0,0,0-.423Zm-.181.356a.323.323,0,0,1-.054.109.3.3,0,0,1-.091.078.359.359,0,0,1-.115.023.363.363,0,0,1-.12-.023.3.3,0,0,1-.091-.078.323.323,0,0,1-.054-.109.386.386,0,0,1-.024-.145.368.368,0,0,1,.024-.146.421.421,0,0,1,.054-.115.2.2,0,0,1,.091-.065.266.266,0,0,1,.12-.03.248.248,0,0,1,.121.03.29.29,0,0,1,.139.181.534.534,0,0,1,.018.146A.563.563,0,0,1,531.476,11167.418Z"
          transform="translate(1.676 -6602.828)"
          fill="#00923a"
        />
        <path
          id="Path_413"
          data-name="Path 413"
          d="M532.871,11167.382a.754.754,0,0,0-.073-.121.232.232,0,0,0-.1-.06v-.006a.331.331,0,0,0,.151-.092.284.284,0,0,0,.048-.175.293.293,0,0,0-.055-.169.164.164,0,0,0-.06-.054.316.316,0,0,0-.072-.037.38.38,0,0,0-.091-.012h-.1l-.446.023.048,1,.193-.013-.018-.392.187-.013a.169.169,0,0,1,.061.007.245.245,0,0,1,.054.029c.018.013.03.03.049.042s.017.037.029.062l.1.247.205-.013Zm-.206-.313a.141.141,0,0,1-.06.029.317.317,0,0,1-.085.013l-.229.012-.018-.3.254-.013a.22.22,0,0,1,.132.03.135.135,0,0,1,.049.109.276.276,0,0,1-.006.072A.1.1,0,0,1,532.665,11167.068Z"
          transform="translate(1.676 -6602.828)"
          fill="#00923a"
        />
        <path
          id="Path_414"
          data-name="Path 414"
          d="M534.126,11166.718a.569.569,0,0,0-.391-.121H533.4v1h.333a.456.456,0,0,0,.524-.507A.509.509,0,0,0,534.126,11166.718Zm-.139.627a.179.179,0,0,1-.1.067.415.415,0,0,1-.156.023h-.14v-.676h.14a.578.578,0,0,1,.156.024.23.23,0,0,1,.1.065.361.361,0,0,1,.073.242A.387.387,0,0,1,533.987,11167.345Z"
          transform="translate(1.676 -6602.828)"
          fill="#00923a"
        />
        <path
          id="Path_415"
          data-name="Path 415"
          d="M534.947,11167.436v-.271l.513.006.007-.163-.52-.006.006-.241.55.006.006-.163-.748-.006-.007,1,.761.007.006-.163Z"
          transform="translate(1.676 -6602.828)"
          fill="#00923a"
        />
        <path
          id="Path_416"
          data-name="Path 416"
          d="M536.777,11166.687l-.278.768-.2-.791-.271-.012-.055,1,.181.006.024-.41.012-.4.217.821.175.013.284-.8-.024.393-.018.417.181.006.049-1Z"
          transform="translate(1.676 -6602.828)"
          fill="#00923a"
        />
        <path
          id="Path_417"
          data-name="Path 417"
          d="M538.962,11167.231l.018-.151-.67-.1-.127.905.688.1.025-.145-.52-.079.037-.24.458.065.025-.145-.465-.066.03-.224Z"
          transform="translate(1.676 -6602.828)"
          fill="#00923a"
        />
        <path
          id="Path_418"
          data-name="Path 418"
          d="M541.051,11167.635a.606.606,0,0,0-.036-.084c-.019-.018-.043-.036-.067-.055a.391.391,0,0,0-.078-.048l-.109-.036-.368-.109-.29.96.187.055.109-.356.181.055a.521.521,0,0,0,.163.024.33.33,0,0,0,.139-.024.263.263,0,0,0,.1-.072.329.329,0,0,0,.066-.127.3.3,0,0,0,.018-.1A.425.425,0,0,0,541.051,11167.635Zm-.193.127a.228.228,0,0,1-.031.066.1.1,0,0,1-.042.037.1.1,0,0,1-.06.012.228.228,0,0,1-.085-.012.08.08,0,0,1-.036-.006c-.012-.007-.024-.007-.036-.013l-.127-.037.091-.3.12.037a.883.883,0,0,1,.1.03.557.557,0,0,1,.066.03l.03.029a.079.079,0,0,1,.013.036.126.126,0,0,1,.006.043A.113.113,0,0,1,540.858,11167.762Z"
          transform="translate(1.676 -6602.828)"
          fill="#00923a"
        />
        <path
          id="Path_419"
          data-name="Path 419"
          d="M542.367,11168a.2.2,0,0,0-.036-.073.287.287,0,0,0-.061-.054.324.324,0,0,0-.072-.043.537.537,0,0,0-.1-.042l-.422-.139-.32.947.187.062.12-.375.182.061a.16.16,0,0,1,.054.03.275.275,0,0,1,.042.042.108.108,0,0,1,.024.061.224.224,0,0,1,.012.066l.006.265.194.067-.012-.284a.309.309,0,0,0-.025-.139.15.15,0,0,0-.071-.09l.005-.006a.284.284,0,0,0,.176-.037.256.256,0,0,0,.108-.139A.316.316,0,0,0,542.367,11168Zm-.2.126a.236.236,0,0,1-.031.067.212.212,0,0,1-.048.029.214.214,0,0,1-.066.007.249.249,0,0,1-.085-.019l-.223-.079.1-.276.236.077a.212.212,0,0,1,.115.073A.139.139,0,0,1,542.168,11168.13Z"
          transform="translate(1.676 -6602.828)"
          fill="#00923a"
        />
        <path
          id="Path_420"
          data-name="Path 420"
          d="M543.641,11168.456a.351.351,0,0,0-.1-.146.542.542,0,0,0-.374-.132.454.454,0,0,0-.181.048.444.444,0,0,0-.145.121.57.57,0,0,0-.1.193.624.624,0,0,0-.036.211.494.494,0,0,0,.036.188.4.4,0,0,0,.109.145.536.536,0,0,0,.374.133.443.443,0,0,0,.181-.048.58.58,0,0,0,.145-.127.518.518,0,0,0,.1-.187.7.7,0,0,0,.042-.213A.657.657,0,0,0,543.641,11168.456Zm-.187.332a.424.424,0,0,1-.06.133.4.4,0,0,1-.091.085.237.237,0,0,1-.109.042.219.219,0,0,1-.121-.018.238.238,0,0,1-.1-.062.21.21,0,0,1-.06-.1.43.43,0,0,1-.019-.127.637.637,0,0,1,.03-.146.419.419,0,0,1,.067-.127.314.314,0,0,1,.09-.084.249.249,0,0,1,.109-.042.2.2,0,0,1,.121.019.245.245,0,0,1,.1.06.223.223,0,0,1,.061.1.256.256,0,0,1,.012.12A.356.356,0,0,1,543.454,11168.788Z"
          transform="translate(1.676 -6602.828)"
          fill="#00923a"
        />
        <path
          id="Path_421"
          data-name="Path 421"
          d="M544.914,11169.2a.379.379,0,0,0,.012-.146.312.312,0,0,0-.036-.133.467.467,0,0,0-.1-.121.886.886,0,0,0-.145-.084.506.506,0,0,0-.2-.048.491.491,0,0,0-.188.036.5.5,0,0,0-.151.107.793.793,0,0,0-.114.183.722.722,0,0,0-.055.211.505.505,0,0,0,.025.193.5.5,0,0,0,.1.15.571.571,0,0,0,.169.115.527.527,0,0,0,.169.042.324.324,0,0,0,.151-.018l-.011.09.114.055.211-.483-.38-.169-.061.14.206.09-.049.115a.329.329,0,0,1-.132.029.423.423,0,0,1-.145-.042.242.242,0,0,1-.1-.066.213.213,0,0,1-.054-.1.26.26,0,0,1-.012-.121.492.492,0,0,1,.043-.15.473.473,0,0,1,.077-.127.374.374,0,0,1,.1-.078.242.242,0,0,1,.115-.03.267.267,0,0,1,.114.024.481.481,0,0,1,.085.054.184.184,0,0,1,.055.06.145.145,0,0,1,.023.073.3.3,0,0,1-.006.072Z"
          transform="translate(1.676 -6602.828)"
          fill="#00923a"
        />
        <path
          id="Path_422"
          data-name="Path 422"
          d="M546.1,11169.513a.2.2,0,0,0-.043-.066.745.745,0,0,0-.072-.055.89.89,0,0,0-.085-.06l-.391-.212-.483.882.168.09.194-.344.162.091a.093.093,0,0,1,.049.036.117.117,0,0,1,.03.054.188.188,0,0,1,.018.062.262.262,0,0,1-.006.065l-.042.261.181.1.042-.277a.533.533,0,0,0,0-.139.306.306,0,0,0-.054-.109.321.321,0,0,0,.187,0,.33.33,0,0,0,.169-.3A.172.172,0,0,0,546.1,11169.513Zm-.194.163a.533.533,0,0,1-.042.061.216.216,0,0,1-.054.023.287.287,0,0,1-.067-.006.272.272,0,0,1-.078-.036l-.205-.108.145-.266.217.12a.224.224,0,0,1,.1.1A.114.114,0,0,1,545.9,11169.676Z"
          transform="translate(1.676 -6602.828)"
          fill="#00923a"
        />
        <path
          id="Path_423"
          data-name="Path 423"
          d="M547.281,11170.249l.084-.146-.646-.367-.495.876.665.374.078-.14-.494-.283.132-.229.447.253.078-.145-.447-.253.121-.212Z"
          transform="translate(1.676 -6602.828)"
          fill="#00923a"
        />
        <path
          id="Path_424"
          data-name="Path 424"
          d="M548.428,11170.853a.268.268,0,0,0-.073-.12.682.682,0,0,0-.12-.108.583.583,0,0,0-.139-.067.318.318,0,0,0-.127-.018.2.2,0,0,0-.108.024.185.185,0,0,0-.091.066c-.006.012-.012.019-.018.03s-.012.018-.012.03a.183.183,0,0,0-.03.108.245.245,0,0,0,.03.1.432.432,0,0,0,.066.1c.036.036.067.078.109.115a.422.422,0,0,0,.067.078c.017.023.029.048.041.066a.155.155,0,0,1,.013.055.066.066,0,0,1-.019.048.109.109,0,0,1-.048.042.068.068,0,0,1-.06.012.189.189,0,0,1-.073-.012.508.508,0,0,1-.078-.042.423.423,0,0,1-.073-.066.174.174,0,0,1-.042-.073.112.112,0,0,1-.012-.077.151.151,0,0,1,.024-.085l-.151-.1a.354.354,0,0,0-.048.283.529.529,0,0,0,.217.254.874.874,0,0,0,.145.072.285.285,0,0,0,.139.019.276.276,0,0,0,.127-.031.354.354,0,0,0,.1-.1.155.155,0,0,0,.043-.085.207.207,0,0,0,.006-.084.4.4,0,0,0-.024-.079.348.348,0,0,0-.049-.078.46.46,0,0,0-.066-.079c-.024-.023-.048-.054-.073-.077a.8.8,0,0,0-.066-.073.225.225,0,0,1-.036-.061.08.08,0,0,1-.012-.048.146.146,0,0,1,.012-.049.11.11,0,0,1,.042-.03c.012-.012.03-.012.054-.012a.145.145,0,0,1,.067.012.2.2,0,0,1,.072.036c.109.073.139.157.091.248l.157.1a.358.358,0,0,0,.042-.127A.31.31,0,0,0,548.428,11170.853Z"
          transform="translate(1.676 -6602.828)"
          fill="#00923a"
        />
        <path
          id="Path_425"
          data-name="Path 425"
          d="M549.539,11171.583a.311.311,0,0,0-.073-.12.726.726,0,0,0-.12-.109.51.51,0,0,0-.139-.065.481.481,0,0,0-.127-.019.213.213,0,0,0-.109.024.259.259,0,0,0-.09.072.119.119,0,0,0-.018.03.152.152,0,0,0-.019.03.151.151,0,0,0-.017.1.19.19,0,0,0,.024.1.567.567,0,0,0,.072.108c.03.037.066.073.1.115s.048.054.066.078.03.042.042.061a.218.218,0,0,1,.012.054.1.1,0,0,1-.017.055.145.145,0,0,1-.043.042.225.225,0,0,1-.066.013.149.149,0,0,1-.067-.019.26.26,0,0,1-.084-.042.365.365,0,0,1-.073-.061.539.539,0,0,1-.042-.072.18.18,0,0,1-.012-.079.187.187,0,0,1,.025-.084l-.152-.1a.325.325,0,0,0-.042.284.451.451,0,0,0,.217.246.319.319,0,0,0,.145.073.366.366,0,0,0,.139.024.28.28,0,0,0,.121-.036.228.228,0,0,0,.1-.091.208.208,0,0,0,.042-.09.133.133,0,0,0,.007-.085.226.226,0,0,0-.025-.078.513.513,0,0,0-.048-.079.353.353,0,0,0-.066-.072.318.318,0,0,0-.072-.079c-.025-.03-.049-.06-.067-.078a.154.154,0,0,1-.036-.061c-.012-.019-.018-.03-.012-.048a.107.107,0,0,1,.012-.042.078.078,0,0,1,.042-.036.179.179,0,0,1,.054-.012.277.277,0,0,1,.067.012.192.192,0,0,1,.072.036c.109.078.139.156.091.247l.157.1a.318.318,0,0,0,.042-.127A.307.307,0,0,0,549.539,11171.583Z"
          transform="translate(1.676 -6602.828)"
          fill="#00923a"
        />
        <path
          id="Path_426"
          data-name="Path 426"
          d="M550.613,11172.465a.32.32,0,0,0-.072-.169.433.433,0,0,0-.144-.133.41.41,0,0,0-.188-.072.377.377,0,0,0-.187.006.632.632,0,0,0-.169.084.622.622,0,0,0-.139.169.7.7,0,0,0-.085.193.556.556,0,0,0-.006.194.446.446,0,0,0,.079.169.475.475,0,0,0,.151.138.532.532,0,0,0,.187.073.473.473,0,0,0,.181-.012.412.412,0,0,0,.169-.092.87.87,0,0,0,.139-.156.685.685,0,0,0,.084-.2A.522.522,0,0,0,550.613,11172.465Zm-.187.151a.569.569,0,0,1-.066.133.353.353,0,0,1-.091.114.379.379,0,0,1-.1.066.277.277,0,0,1-.236-.029.4.4,0,0,1-.084-.079.382.382,0,0,1-.037-.109.3.3,0,0,1,.012-.126.393.393,0,0,1,.061-.133.335.335,0,0,1,.091-.115.657.657,0,0,1,.1-.065.271.271,0,0,1,.121-.013.342.342,0,0,1,.114.042.233.233,0,0,1,.086.084.179.179,0,0,1,.035.109A.422.422,0,0,1,550.426,11172.616Z"
          transform="translate(1.676 -6602.828)"
          fill="#00923a"
        />
      </g>
    </svg>
  );
}
