import PropTypes from 'prop-types';
import React, { useCallback, useRef, useState } from 'react';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useTranslation } from 'react-i18next';
import useLanguage from '../../../hooks/UseLanguage';
import i18n, { changeLanguage, locales } from '../../../i18n';
import { pushDataLayer } from '../../../utils/pushDataLayer';
import IconBrasil from '../Icons/IconBrasil';
import IconUsa from '../Icons/IconUsa';
import './styles.css';

const Icons = { en: <IconUsa />, 'pt-BR': <IconBrasil /> };

function NavDropdownI18n({ getNavDropdownTitle, onClick, gtmCategory }) {
  const language = useLanguage();
  const localesToShow = locales.filter((locale) => locale !== language);
  const [toggleState, setToggleState] = useState('');
  const handleToggle = useCallback(
    (open) => {
      setToggleState(open ? 'showing' : 'closing');
      setTimeout(() => {
        setToggleState('');
      }, 300);
    },
    [setToggleState]
  );

  const { t } = useTranslation();
  const translateLanguage = t('common:languageTranslate', {
    returnObjects: true,
    defaultValue: {},
    lng: locales.includes(i18n.language)
      ? undefined
      : i18n.options.fallbackLng[0],
  });

  const doChangeLanguageCallback = useCallback(
    (lng) => {
      changeLanguage(lng);
      if (onClick) onClick();
    },
    [onClick]
  );

  const dropdownRef = useRef();

  const onClickDropDown = useCallback(
    (e) => {
      if (
        e.target.closest('.languages > .dropdown-toggle') ===
        dropdownRef.current.querySelector('.dropdown-toggle')
      ) {
        pushDataLayer(gtmCategory, 'seletor-idioma');
      }
    },
    [dropdownRef, gtmCategory]
  );

  const onClickLanguage = useCallback(
    (lng) => {
      pushDataLayer(gtmCategory, `idioma_${lng.toUpperCase()}`);
      doChangeLanguageCallback(lng);
    },
    [gtmCategory]
  );
  const getOnClickLanguage = (lng) =>
    function () {
      onClickLanguage(lng);
    };

  return (
    <NavDropdown
      title={
        <>
          <span className="hide-visibility">
            {t('common:menuSiteLanguage')}&ensp;{translateLanguage[language]}
            &ensp;
          </span>
          {getNavDropdownTitle(Icons[language])}
        </>
      }
      ref={dropdownRef}
      className={`nav-item languages ${toggleState}`}
      onToggle={handleToggle}
      onClick={onClickDropDown}
    >
      {localesToShow.map((lng) => (
        <NavDropdown.Item
          key={lng}
          onClick={getOnClickLanguage(lng)}
          active={language === lng}
        >
          <>
            <span className="hide-visibility">
              {t('common:menuSiteLanguageChange')}&ensp;{translateLanguage[lng]}
              &ensp;
            </span>
            {Icons[lng]}
          </>
        </NavDropdown.Item>
      ))}
    </NavDropdown>
  );
}

NavDropdownI18n.propTypes = {
  getNavDropdownTitle: PropTypes.func.isRequired,
  onClick: PropTypes.func,
};

NavDropdownI18n.defaultProps = {
  onClick: null,
};

export default NavDropdownI18n;
