import React from 'react';
import { useTheme, defaultTheme } from '../../../../hooks/UseTheme';

export default function IconLogin() {
  const theme = useTheme();
  return theme === defaultTheme ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="login-box-line"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <path
        id="Path_2570"
        data-name="Path 2570"
        d="M0,0H20V20H0Z"
        fill="none"
      />
      <path
        id="Path_2571"
        data-name="Path 2571"
        d="M3.667,12.833H5.333V17h10V3.667h-10V7.833H3.667v-5A.833.833,0,0,1,4.5,2H16.167A.833.833,0,0,1,17,2.833v15a.833.833,0,0,1-.833.833H4.5a.833.833,0,0,1-.833-.833Zm5-3.333V7l4.167,3.333L8.667,13.667v-2.5H2V9.5Z"
        transform="translate(-0.333 -0.333)"
        fill="#fff"
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      width="20"
      height="20"
      enableBackground="new 0 0 20 20"
      version="1.1"
      viewBox="0 0 20 20"
      xmlSpace="preserve"
    >
      <path d="M3.3 12.5H5v4.2h10V3.3H5v4.2H3.3v-5c0-.5.4-.8.8-.8h11.7c.5 0 .8.4.8.8v15c0 .5-.4.8-.8.8H4.2c-.5 0-.8-.4-.8-.8v-5zm5-3.3V6.7l4.2 3.3-4.2 3.3v-2.5H1.7V9.2h6.6z"></path>
    </svg>
  );
}
