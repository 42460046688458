import ReactOneSignal from 'react-onesignal';

const notifyButtonForceShow = () => {
  if (window.OneSignal?.notifyButton?.launcher?.state === 'hidden')
    window.OneSignal.notifyButton.launcher.show();
};

const showPromptWorkaround = () => {
  if (
    window.OneSignal?.config?.userConfig?.promptOptions?.slidedown?.prompts?.[0]
  ) {
    window.OneSignal.config.userConfig.promptOptions.slidedown.prompts[0].autoPrompt = true;
    window.OneSignal.context?.promptsManager?.spawnAutoPrompts?.();
  }
};

const hidePromptWorkaround = () => {
  if (
    window.OneSignal?.config?.userConfig?.promptOptions?.slidedown?.prompts?.[0]
  ) {
    window.OneSignal.context?.slidedownManager?.setIsSlidedownShowing?.(false);
    window.OneSignal.config.userConfig.promptOptions.slidedown.prompts[0].autoPrompt = false;
    window.OneSignal.slidedown?.close?.();
    notifyButtonForceShow();
  }
};

const showNotifyButtonWorkaround = () =>
  window?.OneSignal?.notifyButton?.container.classList.remove('force-hidden');

const hideNotifyButtonWorkaround = () =>
  window.OneSignal?.notifyButton?.container.classList.add('force-hidden');

const InitOneSignal = (jsonLang) => {
  global.__CLIENT__ &&
    ReactOneSignal.init({
      appId: process.env.REACT_APP_ONESIGNAL_APP_ID,
      safari_web_id: process.env.REACT_APP_ONESIGNAL_SAFARI_WEB_ID,
      subdomainName: process.env.REACT_APP_ONESIGNAL_SUBDOMAIN,
      notifyButton: {
        enable: true /* Required to use the Subscription Bell */,
        size: 'medium' /* One of 'small', 'medium', or 'large' */,
        theme:
          'default' /* One of 'default' (red-white) or 'inverse" (white-red) */,
        position: 'bottom-left' /* Either 'bottom-left' or 'bottom-right' */,
        offset: {
          bottom: '21px',
          left: '10px' /* Only applied if bottom-left */,
          right: '10px' /* Only applied if bottom-right */,
        },
        displayPredicate: function () {
          return ReactOneSignal.isPushNotificationsEnabled().then(function (
            isPushEnabled
          ) {
            /* The user is subscribed, so we want to return "false" to hide the Subscription Bell */
            return !isPushEnabled;
          });
        },
        showCredit: false /* Hide the OneSignal logo */,
        text: jsonLang.config,
        colors: {
          // Customize the colors of the main button and dialog popup button
          'circle.background': 'rgb(84,110,123)',
          'circle.foreground': 'white',
          'badge.background': 'rgb(84,110,123)',
          'badge.foreground': 'white',
          'badge.bordercolor': 'white',
          'pulse.color': 'white',
          'dialog.button.background.hovering': 'rgb(77, 101, 113)',
          'dialog.button.background.active': 'rgb(70, 92, 103)',
          'dialog.button.background': 'rgb(84,110,123)',
          'dialog.button.foreground': 'white',
        },
      },
      welcomeNotification: jsonLang?.welcomeNotification,
      promptOptions: {
        type: 'slidedown',
        autoPrompt: false,
        delay: 0,
        slidedown: {
          autoPrompt: false,
        },
        ...jsonLang.prompt,
      },
    }).then(() => {
      ReactOneSignal.once('animatedElementShowing', () => {
        hideNotifyButtonWorkaround();
        window.dispatchEvent(
          new CustomEvent('OneSignalBell', { detail: 'hide' })
        );
      });
    });
  return {
    oneSignal: ReactOneSignal,
    hide: () => {
      hideNotifyButtonWorkaround();
      hidePromptWorkaround();
    },
    show: () => {
      showNotifyButtonWorkaround();
      showPromptWorkaround();
    },
    removeClass: (className) =>
      window.OneSignal?.notifyButton?.container.classList.remove(className),
    addClass: (className) =>
      window.OneSignal?.notifyButton?.container.classList.add(className),
  };
};

export default InitOneSignal;
