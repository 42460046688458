import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';

const RemoveTrailingSlash = ({ ...rest }) => {
  const location = useLocation();

  // If the last character of the url is '/'
  if (location.pathname.length > 1 && location.pathname.match('/.*/$')) {
    return (
      <Redirect
        {...rest}
        to={{
          ...location,
          pathname: location.pathname.replace(/\/+$/, ''),
        }}
      />
    );
  }
  return null;
};

export default RemoveTrailingSlash;
