import { overrideXHROpenListener } from './utils';

class XHRInterceptor {
  constructor() {
    overrideXHROpenListener(this.listening);
  }

  listeners = [];
  push = (listen) =>
    this.listeners.indexOf(listen) === -1 &&
    this.listeners.push(listen) &&
    console.log('XHR Listener loaded');
  remove = (listen) => {
    this.listeners = this.listeners.filter((item) => item !== listen);
    console.log('XHR Listener removed');
  };
  listening = (xhr) => this.listeners.forEach((item) => item(xhr));
}

const XHRInterceptorInit = () => new XHRInterceptor();

export default XHRInterceptorInit;
