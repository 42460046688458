/* eslint-disable no-underscore-dangle */
export const transitionDuration = 0.4;
export const tabletSize = 768;
export const desktopSize = 992;
export function fadeDuration() {
  if (global.__CLIENT__ && window?.innerWidth) {
    if (window.innerWidth <= tabletSize) return 150; // MOBILE
    if (window.innerWidth <= desktopSize) return 200; // TABLET
  }
  return 400;
}
