export function pushDataLayerWithProps(eventProps, event) {
  const eventAction = eventProps.action ?? 'clique';
  const eventValue = eventProps.value ?? '';
  window.dataLayer.push({
    event: event ?? 'event',
    eventProps: {
      ...eventProps,
      action: eventAction,
      value: eventValue,
    },
  });
  window.dataLayer.push({
    event: `${eventProps.category}_${eventAction}`,
    eventProps,
  });
}

export function pushDataLayer(category, label, action, value, event) {
  pushDataLayerWithProps(
    {
      category,
      action: action,
      label,
      value: value,
    },
    event
  );
}
