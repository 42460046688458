import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import links from '../../../../routes/links';
import { pushDataLayerWithProps } from '../../../../utils/pushDataLayer';
import Button from '../../Button';

function CTAScheduling({
  eventProps,
  className,
  onClick,
  text = '',
  id = '',
  reference = '',
}) {
  const { t } = useTranslation();
  const history = useHistory();
  const schedulingId = id ? `scheduling-${id}` : null;
  const buttonText = text ? text : t('common:btnSchedule');
  const ref = reference ? `#${reference}` : links.scheduling.root;
  const onClickScheduling = useCallback(
    (e) => {
      e.preventDefault();
      pushDataLayerWithProps(eventProps);
      if (onClick) onClick(e);
      else history.push(links.scheduling.root);
    },
    [eventProps, history, onClick]
  );

  return (
    <Button
      id={schedulingId}
      href={ref}
      variant="secondary"
      className={`${className} cta-scheduling-text`}
      onClick={onClickScheduling}
    >
      <span className="cta-scheduling-text">{buttonText}</span>
    </Button>
  );
}

export default CTAScheduling;
