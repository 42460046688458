import React from 'react';
import { PerformanceCookies } from '../index';
import RDStationPersonalization from './RDStationPersonalization';

function MyPerformanceCookies({ cookieOptions }) {
  return (
    <PerformanceCookies cookieOptions={cookieOptions}>
      {({ active, setActive }) => (
        <>
          <RDStationPersonalization active={active} setActive={setActive} />
        </>
      )}
    </PerformanceCookies>
  );
}

export default MyPerformanceCookies;
