import useSWR from 'swr';
import fetchWithoutToken from '../../utils/fetchWithoutToken';
import { prepare, prepareUrl } from './helpers';

export default function useBlogMenu(query) {
  const {
    data: menu,
    error,
    isValidating,
    mutate,
  } = useSWR(prepareUrl(query), fetchWithoutToken, {
    revalidateOnFocus: false,
    revalidateOnReconnect: true,
    errorRetryCount: 8,
  });
  return {
    menu: prepare(menu),
    error,
    isValidating,
    mutate,
  };
}
