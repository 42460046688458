import consoleErrorListener from '../../utils/consoleErrorListener';

const init = () => {
  const newRelicRum = global.__CLIENT__ ? window.newrelic : null;
  newRelicRum &&
    consoleErrorListener((message) => {
      newRelicRum.noticeError(`console.error: ${message}`);
    });
  return newRelicRum;
};

export default init;
