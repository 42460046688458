import React from 'react';
import Button from '../../Button';
import './styles.css';

const CTAPurple = ({ eventProps: _, className, children, ...props }) => {
  return (
    <Button
      {...props}
      variant="custom"
      className={`btn-purple btn-cta ${className ?? ''}`}
    >
      {children}
    </Button>
  );
};

export default CTAPurple;
