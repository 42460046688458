import React from 'react';

export default function IconUsa() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="32"
      height="32"
      viewBox="0 0 72 72"
    >
      <defs>
        <filter
          id="Ellipse_53"
          x="0"
          y="0"
          width="72"
          height="72"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="3" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="3" result="blur" />
          <feFlood floodOpacity="0.506" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g
        id="Group_640"
        data-name="Group 640"
        transform="translate(-1246 -4535)"
      >
        <path
          id="Path_972"
          data-name="Path 972"
          d="M1285.388,11152.191h-39a27.175,27.175,0,0,1,5.1-4.163h28.794A27.168,27.168,0,0,1,1285.388,11152.191Z"
          transform="translate(16.41 -6602.662)"
          fill="#fff"
        />
        <path
          id="Path_973"
          data-name="Path 973"
          d="M1280.284,11148.028H1251.49a26.441,26.441,0,0,1,2.671-1.487c.084-.037.169-.079.261-.122.309-.146.625-.285.946-.425.14-.061.286-.115.425-.17a26.831,26.831,0,0,1,10.088-1.96h.006A26.852,26.852,0,0,1,1280.284,11148.028Z"
          transform="translate(16.41 -6602.662)"
          fill="#a82233"
        />
        <path
          id="Path_974"
          data-name="Path 974"
          d="M1288.673,11156.349H1243.1c.236-.37.485-.74.746-1.1.019-.03.043-.06.067-.091a27.553,27.553,0,0,1,2.47-2.968h39A26.858,26.858,0,0,1,1288.673,11156.349Z"
          transform="translate(16.41 -6602.662)"
          fill="#a82233"
        />
        <path
          id="Path_976"
          data-name="Path 976"
          d="M1290.858,11160.513h-49.941c.11-.267.224-.534.352-.8.291-.638.607-1.269.947-1.876.279-.5.576-1,.886-1.487h45.571A26.97,26.97,0,0,1,1290.858,11160.513Z"
          transform="translate(16.41 -6602.662)"
          fill="#fff"
        />
        <g
          id="Group_340"
          data-name="Group 340"
          transform="translate(1270.572 4542.592)"
        >
          <path
            id="Path_980"
            data-name="Path 980"
            d="M1262.458,11145.254l.382,1.165h1.232l-1,.729.382,1.172-1-.729-1,.729.383-1.172-1-.729h1.232Z"
            transform="translate(-1254.161 -11145.254)"
            fill="#fff"
          />
          <path
            id="Path_981"
            data-name="Path 981"
            d="M1256.516,11148.028l.091.291-.4-.291-.6-.437-.6.438-.394.291.091-.291.285-.88-.831-.607c.084-.037.169-.079.261-.122h.807l.139-.425c.14-.061.286-.115.425-.17l.2.595h1.226l-.99.729Z"
            transform="translate(-1254.161 -11145.254)"
            fill="#fff"
          />
        </g>
        <g
          id="Group_341"
          data-name="Group 341"
          transform="translate(1263.563 4548.412)"
        >
          <path
            id="Path_982"
            data-name="Path 982"
            d="M1262.458,11151.074l.382,1.172h1.232l-1,.722.382,1.172-1-.723-1,.723.383-1.172-1-.722h1.232Z"
            transform="translate(-1247.15 -11151.074)"
            fill="#fff"
          />
          <path
            id="Path_983"
            data-name="Path 983"
            d="M1255.611,11151.074l.382,1.172h1.226l-1,.722.382,1.172-1-.723-1,.723.382-1.172-1-.722h1.233Z"
            transform="translate(-1247.15 -11151.074)"
            fill="#fff"
          />
          <path
            id="Path_984"
            data-name="Path 984"
            d="M1248.765,11151.074l.376,1.172h1.233l-1,.722.382,1.172-1-.723-1,.723.382-1.172-1-.722h1.232Z"
            transform="translate(-1247.15 -11151.074)"
            fill="#fff"
          />
        </g>
        <g
          id="Group_342"
          data-name="Group 342"
          transform="translate(1257.682 4554.239)"
        >
          <path
            id="Path_985"
            data-name="Path 985"
            d="M1262.458,11156.9l.382,1.171h1.232l-1,.723.382,1.172-1-.723-1,.723.383-1.172-1-.723h1.232Z"
            transform="translate(-1241.269 -11156.901)"
            fill="#fff"
          />
          <path
            id="Path_986"
            data-name="Path 986"
            d="M1255.611,11156.9l.382,1.171h1.226l-1,.723.382,1.172-1-.723-1,.723.382-1.172-1-.723h1.233Z"
            transform="translate(-1241.269 -11156.901)"
            fill="#fff"
          />
          <path
            id="Path_987"
            data-name="Path 987"
            d="M1248.765,11156.9l.376,1.171h1.233l-1,.723.382,1.172-1-.723-1,.723.382-1.172-1-.723h1.232Z"
            transform="translate(-1241.269 -11156.901)"
            fill="#fff"
          />
          <path
            id="Path_988"
            data-name="Path 988"
            d="M1242.531,11158.795l.377,1.171-1-.722-.643.468c.291-.638.607-1.269.947-1.876l.079.237h1.226Z"
            transform="translate(-1241.269 -11156.901)"
            fill="#fff"
          />
        </g>
        <g id="Group_637" data-name="Group 637">
          <g
            transform="matrix(1, 0, 0, 1, 1246, 4535)"
            filter="url(#Ellipse_53)"
          >
            <circle
              id="Ellipse_53-2"
              data-name="Ellipse 53"
              cx="27"
              cy="27"
              r="27"
              transform="translate(9 6)"
              fill="#fff"
            />
          </g>
          <path
            id="Path_966"
            data-name="Path 966"
            d="M1290.858,11181.325a26.646,26.646,0,0,1-2.185,4.158H1243.1a26.563,26.563,0,0,1-2.185-4.158Z"
            transform="translate(16.41 -6602.662)"
            fill="#fff"
          />
          <path
            id="Path_967"
            data-name="Path 967"
            d="M1292.211,11177.162a26.158,26.158,0,0,1-1.353,4.163h-49.941a26.768,26.768,0,0,1-1.354-4.163Z"
            transform="translate(16.41 -6602.662)"
            fill="#a82233"
          />
          <path
            id="Path_968"
            data-name="Path 968"
            d="M1292.861,11173a27.136,27.136,0,0,1-.65,4.164h-52.648a27.135,27.135,0,0,1-.649-4.164Z"
            transform="translate(16.41 -6602.662)"
            fill="#fff"
          />
          <path
            id="Path_969"
            data-name="Path 969"
            d="M1288.673,11185.483a26.637,26.637,0,0,1-3.285,4.164h-39a26.989,26.989,0,0,1-3.283-4.164Z"
            transform="translate(16.41 -6602.662)"
            fill="#a82233"
          />
          <path
            id="Path_970"
            data-name="Path 970"
            d="M1280.29,11193.81a26.991,26.991,0,0,1-28.806,0Z"
            transform="translate(16.41 -6602.662)"
            fill="#a82233"
          />
          <path
            id="Path_971"
            data-name="Path 971"
            d="M1285.388,11189.647a26.931,26.931,0,0,1-5.1,4.162h-28.806a27.324,27.324,0,0,1-5.1-4.162Z"
            transform="translate(16.41 -6602.662)"
            fill="#fff"
          />
          <path
            id="Path_975"
            data-name="Path 975"
            d="M1292.211,11164.676h-52.648a27.179,27.179,0,0,1,1.354-4.163h49.941A26.55,26.55,0,0,1,1292.211,11164.676Z"
            transform="translate(16.41 -6602.662)"
            fill="#a82233"
          />
          <path
            id="Path_977"
            data-name="Path 977"
            d="M1292.861,11168.84h-53.947c.012-.176.03-.346.049-.516.042-.51.109-1.014.182-1.518.109-.716.248-1.433.418-2.131h52.648A27.024,27.024,0,0,1,1292.861,11168.84Z"
            transform="translate(16.41 -6602.662)"
            fill="#fff"
          />
          <path
            id="Path_978"
            data-name="Path 978"
            d="M1292.939,11170.916c0,.7-.024,1.4-.078,2.082h-53.947c-.055-.687-.079-1.378-.079-2.082s.024-1.39.079-2.076h53.947C1292.915,11169.526,1292.939,11170.218,1292.939,11170.916Z"
            transform="translate(16.41 -6602.662)"
            fill="#a82233"
          />
          <path
            id="Path_979"
            data-name="Path 979"
            d="M1265.881,11143.864V11173h-26.967c-.055-.687-.079-1.378-.079-2.082s.024-1.39.079-2.076c.012-.176.03-.346.049-.516.042-.51.109-1.014.182-1.518.109-.716.248-1.433.418-2.131a27.179,27.179,0,0,1,1.354-4.163c.11-.267.224-.534.352-.8.291-.638.607-1.269.947-1.876.279-.5.576-1,.886-1.487.236-.37.485-.74.746-1.1.019-.03.043-.06.067-.091a27.553,27.553,0,0,1,2.47-2.968,27.175,27.175,0,0,1,5.1-4.163,26.441,26.441,0,0,1,2.671-1.487c.084-.037.169-.079.261-.122.309-.146.625-.285.946-.425.14-.061.286-.115.425-.17A26.831,26.831,0,0,1,1265.881,11143.864Z"
            transform="translate(16.41 -6602.662)"
            fill="#3c3b6d"
          />
          <g
            id="Group_343"
            data-name="Group 343"
            transform="translate(1256.715 4560.06)"
          >
            <path
              id="Path_989"
              data-name="Path 989"
              d="M1262.458,11162.722l.382,1.172h1.232l-1,.729.382,1.165-1-.723-1,.723.383-1.165-1-.729h1.232Z"
              transform="translate(-1240.304 -11162.722)"
              fill="#fff"
            />
            <path
              id="Path_990"
              data-name="Path 990"
              d="M1255.611,11162.722l.382,1.172h1.226l-1,.729.382,1.165-1-.723-1,.723.382-1.165-1-.729h1.233Z"
              transform="translate(-1240.304 -11162.722)"
              fill="#fff"
            />
            <path
              id="Path_991"
              data-name="Path 991"
              d="M1248.765,11162.722l.376,1.172h1.233l-1,.729.382,1.165-1-.723-1,.723.382-1.165-1-.729h1.232Z"
              transform="translate(-1240.304 -11162.722)"
              fill="#fff"
            />
            <path
              id="Path_992"
              data-name="Path 992"
              d="M1241.912,11162.722l.382,1.172h1.232l-1,.729.383,1.165-1-.723-1,.723.382-1.165-1-.729h1.226Z"
              transform="translate(-1240.304 -11162.722)"
              fill="#fff"
            />
          </g>
          <g
            id="Group_344"
            data-name="Group 344"
            transform="translate(1256.715 4565.887)"
          >
            <path
              id="Path_993"
              data-name="Path 993"
              d="M1262.458,11168.549l.382,1.172h1.232l-1,.722.382,1.172-1-.723-1,.723.383-1.172-1-.722h1.232Z"
              transform="translate(-1240.304 -11168.549)"
              fill="#fff"
            />
            <path
              id="Path_994"
              data-name="Path 994"
              d="M1255.611,11168.549l.382,1.172h1.226l-1,.722.382,1.172-1-.723-1,.723.382-1.172-1-.722h1.233Z"
              transform="translate(-1240.304 -11168.549)"
              fill="#fff"
            />
            <path
              id="Path_995"
              data-name="Path 995"
              d="M1248.765,11168.549l.376,1.172h1.233l-1,.722.382,1.172-1-.723-1,.723.382-1.172-1-.722h1.232Z"
              transform="translate(-1240.304 -11168.549)"
              fill="#fff"
            />
            <path
              id="Path_996"
              data-name="Path 996"
              d="M1241.912,11168.549l.382,1.172h1.232l-1,.722.383,1.172-1-.723-1,.723.382-1.172-1-.722h1.226Z"
              transform="translate(-1240.304 -11168.549)"
              fill="#fff"
            />
          </g>
          <g
            id="Group_345"
            data-name="Group 345"
            transform="translate(1255.375 4562.974)"
          >
            <path
              id="Path_997"
              data-name="Path 997"
              d="M1259.034,11165.636l.383,1.171h1.232l-1,.723.376,1.171-1-.722-1,.722.382-1.171-1-.723h1.232Z"
              transform="translate(-1238.963 -11165.636)"
              fill="#fff"
            />
            <path
              id="Path_998"
              data-name="Path 998"
              d="M1252.188,11165.636l.376,1.171h1.232l-.995.723.382,1.171-1-.722-1,.722.376-1.171-1-.723h1.232Z"
              transform="translate(-1238.963 -11165.636)"
              fill="#fff"
            />
            <path
              id="Path_999"
              data-name="Path 999"
              d="M1245.336,11165.636l.382,1.171h1.232l-1,.723.376,1.171-.995-.722-1,.722.382-1.171-1-.723h1.232Z"
              transform="translate(-1238.963 -11165.636)"
              fill="#fff"
            />
            <path
              id="Path_1000"
              data-name="Path 1000"
              d="M1239.1,11167.529l.382,1.172-.521-.377c.042-.51.109-1.014.182-1.518h.952Z"
              transform="translate(-1238.963 -11165.636)"
              fill="#fff"
            />
          </g>
          <g
            id="Group_346"
            data-name="Group 346"
            transform="translate(1260.139 4557.152)"
          >
            <path
              id="Path_1001"
              data-name="Path 1001"
              d="M1259.034,11159.814l.383,1.172h1.232l-1,.722.376,1.172-1-.729-1,.729.382-1.172-1-.722h1.232Z"
              transform="translate(-1243.727 -11159.814)"
              fill="#fff"
            />
            <path
              id="Path_1002"
              data-name="Path 1002"
              d="M1252.188,11159.814l.376,1.172h1.232l-.995.722.382,1.172-1-.729-1,.729.376-1.172-1-.722h1.232Z"
              transform="translate(-1243.727 -11159.814)"
              fill="#fff"
            />
            <path
              id="Path_1003"
              data-name="Path 1003"
              d="M1245.336,11159.814l.382,1.172h1.232l-1,.722.376,1.172-.995-.729-1,.729.382-1.172-1-.722h1.232Z"
              transform="translate(-1243.727 -11159.814)"
              fill="#fff"
            />
          </g>
        </g>
        <g
          id="Group_347"
          data-name="Group 347"
          transform="translate(1260.26 4551.326)"
        >
          <path
            id="Path_1004"
            data-name="Path 1004"
            d="M1259.034,11153.988l.383,1.171h1.232l-1,.723.376,1.171-1-.722-1,.722.382-1.171-1-.723h1.232Z"
            transform="translate(-1243.848 -11153.988)"
            fill="#fff"
          />
          <path
            id="Path_1005"
            data-name="Path 1005"
            d="M1252.188,11153.988l.376,1.171h1.232l-.995.723.382,1.171-1-.722-1,.722.376-1.171-1-.723h1.232Z"
            transform="translate(-1243.848 -11153.988)"
            fill="#fff"
          />
          <path
            id="Path_1006"
            data-name="Path 1006"
            d="M1246.106,11156.349l.231.7-.971-.7-.03-.018-.031.018-.965.7.231-.7.151-.467-.874-.632c.019-.03.043-.06.067-.091h1.038l.383-1.171.382,1.171h1.232l-1,.723Z"
            transform="translate(-1243.848 -11153.988)"
            fill="#fff"
          />
        </g>
        <g
          id="Group_348"
          data-name="Group 348"
          transform="translate(1266.984 4545.499)"
        >
          <path
            id="Path_1007"
            data-name="Path 1007"
            d="M1259.034,11148.161l.383,1.172h1.232l-1,.728.376,1.166-1-.723-1,.723.382-1.166-1-.728h1.232Z"
            transform="translate(-1250.573 -11148.161)"
            fill="#fff"
          />
          <path
            id="Path_1008"
            data-name="Path 1008"
            d="M1252.188,11148.161l.376,1.172h1.232l-.995.728.382,1.166-1-.723-1,.723.376-1.166-1-.728h1.232Z"
            transform="translate(-1250.573 -11148.161)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
}
