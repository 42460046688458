import home from './home.json';
import common from './common.json';
import ecommerce from './ecommerce.json';
import financial from './financial.json';
import retail from './retail.json';
import healthcare from './healthcare.json';
import internetProvider from './internetProvider.json';
import segments from './segments.json';
import segmentsV2 from './segmentsV2.json';
import cases from './cases.json';
import institutional from './institutional.json';
import scheduling from './scheduling.json';
import newwayInstitute from './newwayInstitute.json';
import features from './features.json';
import blog from './blog.json';
import blogCategories from './blogCategories.json';
import afterScheduling from './afterScheduling.json';
import schedulingDemonstration from './schedulingDemonstration.json';
import talkSuccess from './talkSuccess.json';
import privacyPolicy from './privacyPolicy.json';
import cookiePolicy from './cookiePolicy.json';
import brandGuideLine from './brandGuideLine.json';
import releaseNotes from './releaseNotes.json';
import registerConfirmation from './registerConfirmation.json';
import freeTrial from './freeTrial.json';
import headerInstagram from './headerInstagram.json';
import entrarMktzap from './entrarMktzap.json';
import plans from './plans.json';
import mktzapPage from './mktzapPage.json';
import OneSignal from './oneSignal.json';
import department from './department.json';
import headerBlackFriday from './headerBlackFriday.json';
import whatsAppPage from './whatsAppPage.json';

const lng = {
  test: {
    home,
    common,
    ecommerce,
    financial,
    retail,
    healthcare,
    internetProvider,
    segments,
    segmentsV2,
    cases,
    institutional,
    scheduling,
    newwayInstitute,
    features,
    blog,
    blogCategories,
    afterScheduling,
    schedulingDemonstration,
    talkSuccess,
    privacyPolicy,
    cookiePolicy,
    brandGuideLine,
    releaseNotes,
    registerConfirmation,
    freeTrial,
    headerInstagram,
    entrarMktzap,
    plans,
    mktzapPage,
    OneSignal,
    department,
    headerBlackFriday,
    whatsAppPage,
  },
};

export default lng;
