import CookieConsent, {
  Cookie,
  GeneralCookie,
  AnalyticCookie,
  MarketingCookie,
  FunctionalCookie,
  PerformanceCookie,
  RequiredCookie,
  GeneralCookies,
  AnalyticCookies,
  MarketingCookies,
  FunctionalCookies,
  PerformanceCookies,
  RequiredCookies,
  InitConsent,
  useCookie,
  consts,
  functions,
} from '@gruponewway/aquisicao-gdpr';
import '@gruponewway/aquisicao-gdpr/dist/styles';
import '@gruponewway/aquisicao-gdpr/dist/index.css';
import interopDefault from '../../utils/interopDefault';

const DefaultCookieConsent = interopDefault(CookieConsent);

const { expiresIn } = consts;

export {
  Cookie,
  GeneralCookie,
  AnalyticCookie,
  MarketingCookie,
  FunctionalCookie,
  PerformanceCookie,
  RequiredCookie,
  GeneralCookies,
  AnalyticCookies,
  MarketingCookies,
  FunctionalCookies,
  PerformanceCookies,
  RequiredCookies,
  InitConsent,
  useCookie,
  consts,
  functions,
  expiresIn,
};

export default DefaultCookieConsent;
