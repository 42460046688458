import React from 'react';
import OneSignalContext from '../../contexts/OneSignal';
import InitOneSignal from '../../contexts/OneSignal/init';
import './styles.scss';

export default function OneSignalProvider({ children, i18n }) {
  const oneSignalInit = InitOneSignal(
    i18n.t('oneSignal:data', {
      returnObjects: true,
    })
  );

  return (
    <OneSignalContext.Provider value={oneSignalInit}>
      {children}
    </OneSignalContext.Provider>
  );
}
