import { useEffect } from 'react';
import useMenuApp from '../../hooks/UseMenuApp';

const ChangeHeightMenuApp = ({ mainRef, children }) => {
  const { height } = useMenuApp();

  useEffect(() => {
    if (mainRef?.current) mainRef.current.style.marginTop = `${height}px`;
  }, [mainRef, height]);
  return children;
};

export default ChangeHeightMenuApp;
