import { useEffect, useCallback } from 'react';
import { Cookie, expiresIn } from '../../index';

const rdCookies = ['_rd_experiment_version', '_rdlps_pp'];

function RDStationEssential({ active }) {
  const cookies = rdCookies.map((name) => new Cookie(name, null));
  const cookieCategory = new Cookie('cookieconsent_status_ESSENTIAL', null);

  const changeRDCookies = useCallback(() => {
    const status = active ? 'ALLOW' : 'DENY';
    cookieCategory.get() !== status &&
      cookieCategory.save(status, { expires: expiresIn });
    if (!active)
      cookies.forEach((cookie) => {
        cookie.get() && cookie.del();
      });
  }, [active, cookies, cookieCategory]);

  useEffect(() => {
    changeRDCookies();
  }, [changeRDCookies]);

  return active;
}

export default RDStationEssential;
