import { useEffect, useCallback } from 'react';
import { expiresIn } from '../../index';
import { v4 as uuid } from 'uuid';
import { UserIdCookie } from '../../../../hooks/UseUserIdCookie';

export default function NwSiteUserId({ active }) {
  const userIdCookie = new UserIdCookie();

  const changeUserIdCookies = useCallback(() => {
    const unique_id = uuid();
    const small_id = unique_id.slice(0, 8);
    if (!active) userIdCookie.get() && userIdCookie.del();
    else
      !userIdCookie.get() &&
        userIdCookie.save(small_id, { expires: expiresIn * 10 });
  }, [active, userIdCookie]);

  useEffect(() => {
    changeUserIdCookies();
  }, [changeUserIdCookies]);
}
