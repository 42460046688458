import { useEffect, useCallback } from 'react';
import { Cookie, expiresIn } from '../../index';

const rdCookies = ['cookieconsent_status_MARKETING'];

function RDStationMarketing({ active }) {
  const cookies = rdCookies.map((name) => new Cookie(name, null));

  const changeRDCookies = useCallback(() => {
    const status = active ? 'ALLOW' : 'DENY';
    cookies.forEach((cookie) => {
      cookie.get() !== status && cookie.save(status, { expires: expiresIn });
    });
  }, [active, cookies]);

  useEffect(() => {
    changeRDCookies();
  }, [changeRDCookies]);

  return active;
}

export default RDStationMarketing;
