import React from 'react';
import Helmet from 'react-helmet';

function ChangePageDescription({ description }) {
  return (
    <Helmet>
      {/** General */}
      <meta name="description" content={description} />
      {/** Open Graph */}
      <meta property="og:description" content={description} />
      {/** Twitter */}
      <meta name="twitter:description" content={description} />
    </Helmet>
  );
}

export default ChangePageDescription;
