import React from 'react';
import { AnalyticCookies, AnalyticCookie } from '../index';
import GoogleAnalytics from './GoogleAnalytics';
import Hotjar from './Hotjar';
import RDStationAnalytic from './RDStationAnalytic';

function MyAnalyticCookies({ cookieOptions }) {
  return (
    <AnalyticCookies cookieOptions={cookieOptions}>
      {({ active, setActive }) => {
        const analyticCookie = new AnalyticCookie(null);
        const value = analyticCookie.get();
        return (
          <>
            <GoogleAnalytics
              active={active || value === 'init'}
              setActive={setActive}
              value={analyticCookie.get()}
            />
            <Hotjar
              active={active || value === 'init'}
              setActive={setActive}
              value={analyticCookie.get()}
            />
            <RDStationAnalytic
              active={active && value !== 'init'}
              setActive={setActive}
              value={analyticCookie.get()}
            />
          </>
        );
      }}
    </AnalyticCookies>
  );
}

export default MyAnalyticCookies;
