import React from 'react';
import { FunctionalCookies } from '../index';
import RDStationUncategorized from './RDStationUncategorized';

function MyFunctionalCookies({ cookieOptions }) {
  return (
    <FunctionalCookies cookieOptions={cookieOptions}>
      {({ active, setActive }) => (
        <>
          <RDStationUncategorized active={active} setActive={setActive} />
        </>
      )}
    </FunctionalCookies>
  );
}

export default MyFunctionalCookies;
