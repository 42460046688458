const URL = `${process.env.REACT_APP_CDN_IMG}/images/commons`;

export const nwLogo = `${URL}/nw-logo.png`;

export const nwLogoMini = `${URL}/nw-logo-mini.png`;

export const Dots = `${URL}/boxdots.png`;

export const mktzapLogoVariant = `${URL}/logo-mktzap-inverse.svg`;

export const arabestosBackground1 = `${URL}/arabestos-background-1.svg`;

export const arabestosBackground2 = `${URL}/arabestos-background-2.svg`;

export const arabescoBackgroundV2 = `${URL}/arabesco-background-v2.svg`;

export const iconePlayVideo = `${URL}/icone-play-video.svg`;

export const bandeiraArgentina = `${URL}/bandeira-argentina.svg`;

export const bandeiraPortugal = `${URL}/bandeira-portugal.svg`;

export const bandeiraBrasil = `${URL}/bandeira-brasil.svg`;

export const bandeiraCanada = `${URL}/bandeira-canada.svg`;

export const bandeiraChile = `${URL}/bandeira-chile.svg`;

export const bandeiraColombia = `${URL}/bandeira-colombia.svg`;

export const bandeiraEUA = `${URL}/bandeira-eua.svg`;

export const bandeiraGuiana = `${URL}/bandeira-guiana.svg`;

export const bandeiraMexico = `${URL}/bandeira-mexico.svg`;

export const bandeiraPeru = `${URL}/bandeira-peru.svg`;

export const backgroundArabestos = `${URL}/background-home-v2.svg`;

export const iconsDiscord = `${URL}/icons-discord.svg`;
