import React from 'react';
import NewRelicRumContext from '../../contexts/NewRelicRum';
import NewRelicRumInit from '../../contexts/NewRelicRum/init';

export default function NewRelicRumProvider({ children }) {
  const newRelicRumInit = NewRelicRumInit();

  return (
    <NewRelicRumContext.Provider value={newRelicRumInit}>
      {children}
    </NewRelicRumContext.Provider>
  );
}
