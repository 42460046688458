import { useState, useCallback, useEffect } from 'react';
import { getLanguage } from '../../i18n';

function useLanguage() {
  const [language, setLanguage] = useState(getLanguage());

  const changeLanguageListener = useCallback(
    (event) => {
      setLanguage(event.detail);
    },
    [setLanguage]
  );

  useEffect(() => {
    window.addEventListener('change-language', changeLanguageListener);
    return () => {
      window.removeEventListener('change-language', changeLanguageListener);
    };
  }, [changeLanguageListener]);

  return language;
}

export default useLanguage;
