import React from 'react';
import { useTranslation } from 'react-i18next';
import AnalyticCookies from '../AnalyticCookies';
import FunctionalCookies from '../FunctionalCookies';
import MarketingCookies from '../MarketingCookies';
import PerformanceCookies from '../PerformanceCookies';
import RequiredCookies from '../RequiredCookies';
import CookieConsent, { InitConsent } from '../index';
import UseCookieOptions from '../../../hooks/UseCookieOptions';

function MyCookieConsent() {
  const { t } = useTranslation();
  const cookieOptions = UseCookieOptions();

  return (
    <InitConsent cookieOptions={cookieOptions} analyticCookieDefault="init">
      <CookieConsent
        buttonAcceptanceText={t('common:GDPR.cookieConsent.buttonAcceptance')}
        title={t('common:GDPR.cookieConsent.title')}
        textConsent={t('common:GDPR.cookieConsent.textConsent')}
        collapseOptions={t('common:GDPR.cookieConsent.collapseOptions')}
        collapsedOptions={t('common:GDPR.cookieConsent.collapsedOptions')}
        optionsLabel={t(
          'common:GDPR.cookieConsent.buttonPersonalizedAcceptance'
        )}
        analyticCaption={t('common:GDPR.cookieConsent.analyticCaption')}
        functionalCaption={t('common:GDPR.cookieConsent.functionalCaption')}
        marketingCaption={t('common:GDPR.cookieConsent.marketingCaption')}
        performanceCaption={t('common:GDPR.cookieConsent.performanceCaption')}
        requiredCaption={t('common:GDPR.cookieConsent.requiredCaption')}
        activeCaption={t('common:GDPR.cookieConsent.activeCaption')}
        inactiveCaption={t('common:GDPR.cookieConsent.inactiveCaption')}
        cookieOptions={cookieOptions}
      />
      <AnalyticCookies cookieOptions={cookieOptions} />
      <FunctionalCookies cookieOptions={cookieOptions} />
      <MarketingCookies cookieOptions={cookieOptions} />
      <PerformanceCookies cookieOptions={cookieOptions} />
      <RequiredCookies cookieOptions={cookieOptions} />
    </InitConsent>
  );
}

export default MyCookieConsent;
