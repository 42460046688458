import React from 'react';
import { I18nextProvider } from 'react-i18next';
import Helmet from 'react-helmet';
import { FacebookProvider } from 'react-facebook';
import { ParallaxProvider } from 'react-scroll-parallax';
import useLanguage from './hooks/UseLanguage';

import App from './App';
import ScrollToTop from './components/Base/ScrollToTop';
import OneSignalProvider from './components/OneSignalProvider';
import NewRelicRumProvider from './components/NewRelicRumProvider';
import XHRInterceptorProvider from './components/XHRInterceptorProvider';
import MenuAppProvider from './components/MenuAppProvider';
import RDStationLoaderProvider from './components/RDStationLoaderProvider';

function Body({ routes, pageProps, i18n }) {
  const language = useLanguage();

  return (
    <I18nextProvider i18n={i18n}>
      <OneSignalProvider i18n={i18n}>
        <FacebookProvider
          appId={i18n.t('common:facebookAppId')}
          language={language.replace('-', '_')}
        >
          <ParallaxProvider>
            <NewRelicRumProvider>
              <XHRInterceptorProvider>
                <MenuAppProvider>
                  <RDStationLoaderProvider>
                    <Helmet htmlAttributes={{ lang: language }}>
                      <meta
                        property="fb:app_id"
                        content={i18n.t('common:facebookAppId')}
                      />
                      <script
                        src={`https://www.googleoptimize.com/optimize.js?id=${process.env.REACT_APP_GOOGLE_OPTIMIZE}`}
                      />
                    </Helmet>
                    <ScrollToTop />
                    <App routes={routes} pageProps={pageProps} />
                  </RDStationLoaderProvider>
                </MenuAppProvider>
              </XHRInterceptorProvider>
            </NewRelicRumProvider>
          </ParallaxProvider>
        </FacebookProvider>
      </OneSignalProvider>
    </I18nextProvider>
  );
}

export default Body;
