import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { useHistory } from 'react-router-dom';

import Accessibilities from './components/Accessibilities';
import Footer from './components/Base/Footer';
import CookieConsent from './components/GDPR/CookieConsent';
import MenuApp from './components/MenuApp';
import { defaultTheme, useTheme } from './hooks/UseTheme';
import Routes from './routes';

import 'aos/dist/aos.css'; // You can also use <link> for styles
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-modal-video/scss/modal-video.scss';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.scss';
import './assets/styles/global.css';
import './assets/styles/mixins.scss';
import './assets/styles/theme.scss';
import OneSignal from './components/OneSignal';
import useLoginAccess from './hooks/UseLoginAccess';
import links from './routes/links';

const pagesLayout = {
  default: {
    show: ['menu', 'footer', 'accessibilities', 'cookie_consent'],
  },
  [links.brandGuideLine.root]: {
    show: [],
  },
  [links.homeV1.root]: {
    pagePrivate: true,
  },
  [links.releaseNotes.root]: {
    pagePrivate: true,
  },
  [links.mktzapV1.root]: {
    pagePrivate: true,
  },
  [links.whatsAppLinkGenerator.root]: {
    show: ['cookie_consent', 'accessibilities'],
  },
  [links.contracts.root]: {
    show: [],
  },
};

function App({ routes, pageProps }) {
  const history = useHistory();
  const getLayout = (pathname) => {
    if (Object.keys(pagesLayout).includes(pathname));
    return { ...pagesLayout.default, ...pagesLayout[pathname] };
  };
  const [pageLayout, setPageLayout] = useState(
    getLayout(history?.location?.pathname)
  );
  const accessPermitted = useLoginAccess();
  const theme = useTheme();

  useEffect(() => {
    return history?.listen?.((location) => {
      setPageLayout(getLayout(location?.pathname));
    });
  }, []);

  const show = (prop) =>
    pageLayout.show.includes(prop) &&
    (!pageLayout.pagePrivate || (pageLayout.pagePrivate && accessPermitted));

  const focusAccessibilityEl =
    !pageLayout.pagePrivate || (pageLayout.pagePrivate && accessPermitted);

  return (
    <div className={`App ${theme}`}>
      <Helmet>
        <meta
          name="theme-color"
          content={theme !== defaultTheme ? 'black' : 'white'}
        />
      </Helmet>
      <div id="app-wrapper">
        {show('menu') && <MenuApp pageProps={pageProps} />}
        <OneSignal theme={theme}>
          <Routes
            routes={routes}
            pageProps={pageProps}
            focusAccessibilityEl={focusAccessibilityEl}
          />
        </OneSignal>
        {show('accessibilities') && <Accessibilities />}
        {show('cookie_consent') && <CookieConsent />}
        {show('footer') && <Footer />}
      </div>
    </div>
  );
}

export default App;
