import afterScheduling from './afterScheduling.json';
import blog from './blog.json';
import blogCategories from './blogCategories.json';
import cases from './cases.json';
import common from './common.json';
import department from './department.json';
import ecommerce from './ecommerce.json';
import entrarMktzap from './entrarMktzap.json';
import errorPage from './errorPage.json';
import features from './features.json';
import financial from './financial.json';
import freeTrial from './freeTrial.json';
import headerBlackFriday from './headerBlackFriday.json';
import headerInstagram from './headerInstagram.json';
import healthcare from './healthcare.json';
import home from './home.json';
import homeV2 from './homeV2.json';
import institutional from './institutional.json';
import internetProvider from './internetProvider.json';
import mktzapPage from './mktzapPage.json';
import newsletter from './newsletter.json';
import newwayInstitute from './newwayInstitute.json';
import oneSignal from './oneSignal.json';
import plans from './plans.json';
import registerConfirmation from './registerConfirmation.json';
import releaseNotes from './releaseNotes.json';
import retail from './retail.json';
import scheduling from './scheduling.json';
import schedulingDemonstration from './schedulingDemonstration.json';
import segments from './segments.json';
import segmentsV2 from './segmentsV2.json';
import talkSuccess from './talkSuccess.json';
import whatsAppPage from './whatsAppPage.json';

const lng = {
  en: {
    home,
    common,
    ecommerce,
    financial,
    retail,
    healthcare,
    internetProvider,
    segments,
    segmentsV2,
    cases,
    institutional,
    scheduling,
    newwayInstitute,
    features,
    blog,
    blogCategories,
    afterScheduling,
    schedulingDemonstration,
    talkSuccess,
    releaseNotes,
    registerConfirmation,
    freeTrial,
    headerInstagram,
    entrarMktzap,
    homeV2,
    errorPage,
    plans,
    mktzapPage,
    newsletter,
    oneSignal,
    department,
    headerBlackFriday,
    whatsAppPage,
  },
};

export default lng;
