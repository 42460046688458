const url = `${process.env.REACT_APP_SERVICE_BLOG}/menu`;

export function prepareUrl(query) {
  const searchQuery = {
    menuId: query.menuId,
    siteId: process.env.REACT_APP_WORDPRESS_SITE_ID,
  };
  return `${url}?${new URLSearchParams(searchQuery).toString()}`;
}

export function prepare(receive) {
  return receive && receive.data ? receive.data.menu : null;
}
