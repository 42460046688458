/* eslint-disable no-underscore-dangle */
import React, { useRef } from 'react';
import Helmet from 'react-helmet';
import { Route, Switch, useLocation } from 'react-router-dom';
import ChangePageDescription from '../components/ChangePageDescription';
import ChangePageTitle from '../components/ChangePageTitle';
import PageWrapper from '../components/PageWrapper';
import RDStationLoader from '../components/RDStationLoader';
import RemoveTrailingSlash from '../components/RemoveTrailingSlash';
import { getLanguage, locales } from '../i18n';
import removeLastSlash from '../utils/removeLastSlash';
import ChangeHeightMenuApp from '../components/ChangeHeightMenuApp';

const RoutesSwitch = ({ routes, pageProps, focusAccessibilityEl }) => {
  const location = useLocation();
  return (
    <Switch location={location} key={location.pathname}>
      {routes.routes.map((route) => (
        <Route exact={route.exact ?? true} key={route.path} path={route.path}>
          <PageWrapper route={route} isClient={global.__CLIENT__}>
            <RDStationLoader loadFormsScript={route.loadRDStationFormsScript}>
              <ChangePageTitle
                title={route.title}
                defaultTitle={routes.defaultRouteTitle}
                queryEl="h1"
                queryFallback={
                  focusAccessibilityEl ? '[tabindex]:not(h1)' : undefined
                }
                changeMeta
              />
              <ChangePageDescription
                description={
                  route.description ?? routes.defaultRouteDescription
                }
              />
              <Helmet>
                <meta
                  property="og:language"
                  content={getLanguage().replace('-', '_')}
                />
                {locales.map(
                  (locale) =>
                    locale !== getLanguage() && (
                      <meta
                        key={locale}
                        property="og:language:alternate"
                        content={locale.replace('-', '_')}
                      />
                    )
                )}
                <meta property="og:type" content="website" />
                <meta
                  property="og:image"
                  itemProp="image"
                  content={route.thumbnail ?? routes.defaultRouteThumbnail}
                />
                <meta
                  property="og:url"
                  content={`${process.env.REACT_APP_SSR_URL}${location.pathname}${location.search}`}
                />
                {/** Twitter */}
                <meta name="twitter:card" content="summary" />
                <meta
                  name="twitter:image:src"
                  content={route.thumbnail ?? routes.defaultRouteThumbnail}
                />
                {/** MS Tile - for Microsoft apps */}
                <meta
                  name="msapplication-TileImage"
                  content={route.thumbnail ?? routes.defaultRouteThumbnail}
                />
                <link
                  href={`${process.env.REACT_APP_SSR_URL}${removeLastSlash(
                    location.pathname
                  )}`}
                  rel="canonical"
                />
                <link
                  rel="alternate"
                  href={`${process.env.REACT_APP_SSR_URL}${location.pathname}`}
                  hrefLang="pt-br"
                />
              </Helmet>
              <route.Component
                redirectTo={route.redirectTo}
                {...(pageProps &&
                pageProps.filter((pageProp) => pageProp.path === route.path)
                  ?.length
                  ? pageProps
                      .filter((pageProp) => pageProp.path === route.path)
                      .map((pageProp) => pageProp.props)
                      .reduce((obj, menuProps) => ({
                        ...obj,
                        ...menuProps,
                      }))
                  : {})}
              />
            </RDStationLoader>
          </PageWrapper>
        </Route>
      ))}
    </Switch>
  );
};

function Routes(props) {
  const mainRef = useRef();

  return (
    <main id="main-section" ref={mainRef}>
      <ChangeHeightMenuApp mainRef={mainRef} />
      {global.__SERVER__ ? (
        <RoutesSwitch {...props} />
      ) : (
        <React.Suspense fallback={<div className="suspense" />}>
          <RemoveTrailingSlash />
          <RoutesSwitch {...props} />
        </React.Suspense>
      )}
    </main>
  );
}

export default Routes;
