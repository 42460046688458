export default class ScriptLoader {
  constructor({
    src,
    global,
    id,
    onScriptReady,
    async = true,
    type = 'text/javascript',
  }) {
    this.src = src;
    this.global = global;
    this.type = type;
    this.isLoaded = false;
    this.id = id;
    this.async = async;
    this.onScriptReady = onScriptReady;
  }

  doOnScriptReady(script) {
    this.onScriptReady && this.onScriptReady(script);
  }

  loadScript() {
    return new Promise((resolve, reject) => {
      // Create script element and set attributes
      const script = document.createElement('script');
      script.type = this.type;
      script.async = this.async;
      script.src = this.src;
      script.id = this.id;

      this.doOnScriptReady(script);

      // Resolve the promise once the script is loaded
      script.addEventListener('load', () => {
        this.isLoaded = true;
        resolve(script);
      });

      // Catch any errors while loading the script
      script.addEventListener('error', () => {
        reject(new Error(`${this.src} failed to load.`));
      });
    });
  }

  load() {
    return new Promise(async (resolve, reject) => {
      if (!this.isLoaded) {
        try {
          await this.loadScript();
          resolve(this.global);
        } catch (e) {
          reject(e);
        }
      } else {
        resolve(this.global);
      }
    });
  }

  clear() {
    this.isLoaded = false;
  }
}
