import React, { useState } from 'react';
import MenuAppContext from '../../contexts/MenuApp';

const defaultValues = { height: 0 };

export default function MenuAppProvider({ children }) {
  const [value, setValue] = useState({
    ...defaultValues,
    change: (newValue) =>
      setValue((prev) => ({
        ...defaultValues,
        ...newValue,
        change: prev.change,
      })),
  });

  return (
    <MenuAppContext.Provider value={value}>{children}</MenuAppContext.Provider>
  );
}
