import { useCallback, useEffect, useState } from 'react';
import useCheckDomainGruponewway from '../UseCheckDomainGruponewway';

export default function UseCookieOptions() {
  const domainGruponewway = useCheckDomainGruponewway('');
  const getDomain = (domain) =>
    domain
      ? {
          domain,
        }
      : undefined;
  const cbDomainGruponewway = useCallback(
    (domain) => setCookieOptions(getDomain(domain)),
    []
  );
  const [cookieOptions, setCookieOptions] = useState(
    getDomain(domainGruponewway)
  );

  useEffect(() => {
    cbDomainGruponewway(domainGruponewway);
  }, [domainGruponewway, cbDomainGruponewway]);

  return cookieOptions;
}
