import { useEffect, useCallback, useState } from 'react';
import { Cookie, expiresIn } from '../../index';
import { useRDStationLoaderListener } from '../../../RDStationLoader';

const rdCookies = ['cookieconsent_status_ANALYTICS'];

function RDStationAnalytic({ active }) {
  const cookies = rdCookies.map((name) => new Cookie(name, null));
  const [isConsent, setIsConsent] = useState(active);

  const changeRDCookies = useCallback(() => {
    const status = active ? 'ALLOW' : 'DENY';
    cookies.forEach((cookie) => {
      cookie.get() !== status && cookie.save(status, { expires: expiresIn });
    });
    if (isConsent !== active) {
      setIsConsent(active);
      global.__CLIENT__ && window.location.reload();
    }
  }, [active, cookies, isConsent]);

  const scriptLoaded = useRDStationLoaderListener();

  if (
    scriptLoaded &&
    window.RDCookieControl &&
    window.RDCookieControl.analytics
  )
    window.RDCookieControl.analytics.track = active;

  useEffect(() => {
    changeRDCookies();
  }, [changeRDCookies]);

  return active;
}

export default RDStationAnalytic;
