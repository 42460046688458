import React from 'react';
import { MarketingCookies } from '../index';
import RDStationMarketing from './RDStationMarketing';

function MyMarketingCookies({ cookieOptions }) {
  return (
    <MarketingCookies cookieOptions={cookieOptions}>
      {({ active, setActive }) => (
        <>
          <RDStationMarketing active={active} setActive={setActive} />
        </>
      )}
    </MarketingCookies>
  );
}

export default MyMarketingCookies;
