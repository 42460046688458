import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useOneSignal from '../../hooks/UseOneSignal';
import links from '../../routes/links';

export default function OneSignal({ theme, children }) {
  const history = useHistory();
  const { show, hide, removeClass, addClass } = useOneSignal() ?? {};
  const [lastTheme, setLastTheme] = useState(theme);

  const visibility = (location) => {
    const isBlogPath = new RegExp(links.blog.root, 'i').test(location.pathname);
    if (show && hide) {
      isBlogPath ? show() : hide();
    }
  };

  const changeTheme = useCallback(
    (theme_) => {
      removeClass && removeClass(lastTheme);
      addClass && addClass(theme_);
      setLastTheme(theme_);
    },
    [theme, lastTheme]
  );

  const showOnOneSignalLoaded = useCallback(() => {
    visibility(history.location);
    changeTheme(theme);
  }, [history]);

  useEffect(() => {
    changeTheme(theme);
  }, [theme]);

  useEffect(() => {
    const unlisten = history?.listen?.((location) => visibility(location));
    window.addEventListener('OneSignalBell', showOnOneSignalLoaded);
    visibility(history.location);
    return () => {
      window.removeEventListener('OneSignalBell', showOnOneSignalLoaded);
      unlisten();
    };
  }, [history]);

  return children;
}
