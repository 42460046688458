export const GetXHROpenListener = (onLoad, XHROpen) => {
  return function (...args) {
    this.addEventListener('load', function load() {
      onLoad(this);
    });
    return XHROpen.apply(this, args);
  };
};

export const overrideXHROpenListener = (
  onLoad,
  getXHROpenListener = GetXHROpenListener
) => {
  if (typeof window !== 'undefined' && window.XMLHttpRequest) {
    if (!window.oldXHROpen) {
      window.oldXHROpen = window.XMLHttpRequest.prototype.open;
      window.XMLHttpRequest.prototype.open = getXHROpenListener(
        onLoad,
        window.oldXHROpen
      );
      console.log('XHR Interceptor loaded');
    }
  }
};
