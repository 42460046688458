import React, { useCallback, useEffect, useState } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import { useHistory } from 'react-router-dom';

const MenuCollapse = ({ refParent, children, ...props }) => {
  const history = useHistory();

  const [expanded, SetExpanded] = useState(false);

  const setNavExpanded = useCallback(
    (isExpanded) => {
      SetExpanded(isExpanded);
      const el = refParent.current;
      if (el) {
        if (isExpanded) el.classList.add('fixed');
        else el.classList.remove('fixed');
      }
    },
    [SetExpanded]
  );

  useEffect(() => {
    return history?.listen?.(() => {
      setNavExpanded(false);
    });
  }, []);

  const onScroll = () => {
    const el = refParent?.current;
    if (el) {
      if (
        document.body.scrollTop > 50 ||
        document.documentElement.scrollTop > 50
      ) {
        el.classList.add('sticky');
      } else {
        el.classList.remove('sticky');
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  return (
    <Navbar
      id="navbar"
      collapseOnSelect
      expand="lg"
      expanded={expanded}
      onToggle={setNavExpanded}
      aria-labelledby="navbar__title"
      {...props}
    >
      {children}
    </Navbar>
  );
};

export default MenuCollapse;
