import { useEffect } from 'react';
import addRDStationScript, {
  RDStationFormsScriptSingletonName,
  RDStationLoaderSingletonName,
} from '../../utils/addRDStationScript';
import remRDStationScript from '../../utils/remRDStationScript';
import useRDStationLoader from '../../hooks/UseRDStationLoader';

function RDStationLoader({ children, loadFormsScript }) {
  const RDStationValue = useRDStationLoader();

  useEffect(() => {
    addRDStationScript(
      (singletonName) => RDStationValue.push(singletonName),
      loadFormsScript
    );

    return () => {
      remRDStationScript(loadFormsScript);
      RDStationValue.clear();
    };
  }, [loadFormsScript]);

  return children;
}

function useRDStationListener() {
  const { scripts = [] } = useRDStationLoader() ?? {};

  return scripts;
}

function useRDStationLoaderListener() {
  const loadedScripts = useRDStationListener();

  return loadedScripts.includes(RDStationLoaderSingletonName);
}

function useRDStationFormsScriptListener() {
  const loadedScripts = useRDStationListener();

  return loadedScripts.includes(RDStationFormsScriptSingletonName);
}

export {
  useRDStationListener,
  useRDStationLoaderListener,
  useRDStationFormsScriptListener,
};

export default RDStationLoader;
