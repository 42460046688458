import ScriptLoader from './scriptLoader';

export const RDStationLoaderSingletonName = 'RDStation';
export const RDStationFormsScriptSingletonName = 'RDStationForms';

export const RDStationLoaderScript = new ScriptLoader({
  id: RDStationLoaderSingletonName,
  global: RDStationLoaderSingletonName,
  src: process.env.REACT_APP_RDSTATION_SCRIPT,
  onScriptReady: (script) => document.body.appendChild(script),
});

export const RDStationFormsScript = new ScriptLoader({
  id: RDStationFormsScriptSingletonName,
  global: RDStationFormsScriptSingletonName,
  src: process.env.REACT_APP_RDSTATION_FORMS_SCRIPT,
  onScriptReady: (script) => document.head.prepend(script),
});

export default function addRDStationScript(onLoad, loadFormsScript) {
  if (RDStationLoaderScript.src) {
    RDStationLoaderScript.load()
      .then(onLoad)
      .catch((error) => {
        console.error(error);
      });
  } else console.warn('Script RDStation not configure!');
  if (loadFormsScript && RDStationFormsScript.src) {
    RDStationFormsScript.load()
      .then(onLoad)
      .catch((error) => {
        console.error(error);
      });
  }
}
