import React from 'react';
import { useTranslation } from 'react-i18next';
import MenuBlog from './index';
import useBlogMenu from '../../../hooks/UseBlogMenu';

function Menu({ refParent }) {
  const { t } = useTranslation();

  const data = useBlogMenu({ menuId: t('common:menuBlogWordpressId') });
  const menu = data.error
    ? { items: t('blog:menu', { returnObjects: true }) }
    : data.menu;

  return <MenuBlog menu={menu} refParent={refParent} />;
}

export default Menu;
